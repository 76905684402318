import { useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  Modal,
  Upload,
  message,
  Row,
  Col,
  Checkbox,
  Select,
} from "antd";
import { useAddTripTypeMutation } from "../../../redux/Service/api";
import FormItemLabel from "antd/es/form/FormItemLabel";

const AddType = ({ setOpenModal, openModal }: any) => {
  const [modalKey, setModalKey] = useState(0);
  const [file, setFile] = useState<File | null>(null);
  const [name, setName] = useState("");
  const [isTrip, setIsTrip] = useState<any>(false);

  const [addTripType, { isLoading }] = useAddTripTypeMutation();

  const handleFileChange = (fileList: any) => {
    if (fileList.length > 0) {
      setFile(fileList[0].originFileObj);
    } else {
      setFile(null);
    }
  };

  const handleAddTripType = async () => {
    if (!file) {
      message.error("يرجى رفع ملف");
      return;
    }
    if (!name) {
      message.error("يرجى إدخال اسم نوع الخدمة");
      return;
    }
    const formData = new FormData();
    formData.append("ServiceTypeImage", file);
    formData.append("ServiceTypeName", name);
    formData.append("IsTrip", isTrip);

    try {
      await addTripType(formData);
      message.success("تمت إضافة نوع الخدمة بنجاح");
      setFile(null);
      setName("");
      setModalKey((prev) => prev + 1);
      setOpenModal(false);
    } catch (error) {
      console.error("خطأ أثناء إضافة نوع الخدمة:", error);
      message.error("فشل في إضافة نوع الخدمة. يرجى المحاولة مرة أخرى.");
    }
  };
  return (
    <Modal
      title="إضافة نوع الخدمة"
      centered
      open={openModal}
      onCancel={() => setOpenModal(false)}
      footer={null}
      key={modalKey}
    >
      <div className="flex flex-col gap-4">
        <Row gutter={16}>
          <Col span={24}>
            <Input
              size="large"
              placeholder="أدخل اسم النوع"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <div className="flex gap-2 items-center mt-2">
            <label>النوع</label>
            <Select
              placeholder="اختر نوع الخدمة"

              options={[
                { label: "خدمة", value: false },
                { label: "رحلة", value: true },
              ]}
              onChange={(value) => setIsTrip(value)}
              value={isTrip}
              />
              </div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Upload
              customRequest={({ file, onSuccess }) => {
                onSuccess?.("ok");
              }}
              onChange={({ fileList }) => handleFileChange(fileList)}
              listType="picture"
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>رفع الملف (بحد أقصى: 1)</Button>
            </Upload>
          </Col>
        </Row>
        <Button type="primary" onClick={handleAddTripType} loading={isLoading}>
          إضافة
        </Button>
      </div>
    </Modal>
  );
};

export default AddType;
