import { Button, Form, Input, Modal, message } from "antd";
import { useEditBranchMutation } from "../../../redux/Service/api";
import { useEffect } from "react";

const EditBranch = ({
  openModal,
  setOpenModal,
  entity,
}: {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  entity: any; // Replace `any` with the proper type if available
}) => {
  const [form] = Form.useForm();
  const [editBranch, { isLoading }] = useEditBranchMutation();

  const handleEditBranch = async () => {
    try {
      const values = await form.validateFields();
      const payload = { ...values, BranchId: entity.BranchID }; // Assuming `BranchId` is the unique identifier
      await editBranch(payload);
      message.success("تم تعديل الفرع بنجاح");
      form.resetFields();
      setOpenModal(false);
    } catch (error) {
      console.error("خطأ أثناء تعديل الفرع:", error);
      message.error("فشل في تعديل الفرع. يرجى المحاولة مرة أخرى.");
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setOpenModal(false);
  };

  useEffect(() => {
    form.setFieldsValue({
      BranchName: entity?.BranchName || "",
    });
  }, [entity]);

  return (
    <Modal
      title="تعديل الفرع"
      centered
      open={openModal}
      onCancel={handleCancel}
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={handleEditBranch}>
        {/* Branch Name Input */}
        <Form.Item
          label="اسم الفرع"
          name="BranchName"
          rules={[{ required: true, message: "يرجى إدخال اسم الفرع" }]}
        >
          <Input size="large" placeholder="أدخل اسم الفرع" />
        </Form.Item>

        {/* Submit Button */}
        <Button type="primary" htmlType="submit" loading={isLoading} block>
          تعديل
        </Button>
      </Form>
    </Modal>
  );
};

export default EditBranch;
