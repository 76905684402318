import { Button, Form, Input, InputNumber, Modal, Switch, message } from "antd";
import { useEditCurrencyMutation } from "../../../redux/Service/api";
import { useEffect } from "react";

const EditCurrency = ({
  openModal,
  setOpenModal,
  entity,
}: {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  entity: any;
}) => {
  const [form] = Form.useForm();
  const [editCurrency, { isLoading }] = useEditCurrencyMutation();

  const handleEditCurrency = async (values: {
    CurrName: string;
    CurrPart: string;
    CurrRate: number;
    IsDefualte: boolean;
  }) => {
    try {
      const payload = { ...values, CurrId: entity.CurrID };

      console.log("entity", entity);
      console.log("Payload", payload);
      // return;
      await editCurrency(payload).unwrap();
      message.success("تم تعديل العملة بنجاح");
      form.resetFields();
      setOpenModal(false);
    } catch (error) {
      console.error("خطأ أثناء تعديل العملة:", error);
      message.error("فشل في تعديل العملة. يرجى المحاولة مرة أخرى.");
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      CurrName: entity?.CurrName || "",
      CurrPart: entity?.CurrPart || "",
      CurrRate: entity?.CurrRate || 0,
      IsDefualte: entity?.IsDefualte || false,
    });
  }, [entity, openModal]);

  const handleCancel = () => {
    form.resetFields();
    setOpenModal(false);
  };

  return (
    <Modal
      title="تعديل العملة"
      centered
      open={openModal}
      onCancel={handleCancel}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleEditCurrency}
        autoComplete="off"
      >
        <Form.Item
          label="اسم العملة"
          name="CurrName"
          rules={[{ required: true, message: "يرجى إدخال اسم العملة" }]}
        >
          <Input size="large" placeholder="أدخل اسم العملة" />
        </Form.Item>

        <Form.Item
          label="الوحدة الفرعية"
          name="CurrPart"
          rules={[{ required: true, message: "يرجى إدخال الوحدة الفرعية" }]}
        >
          <Input size="large" placeholder="أدخل الوحدة الفرعية" />
        </Form.Item>

        <Form.Item
          label="سعر الصرف"
          name="CurrRate"
          rules={[{ required: true, message: "يرجى إدخال سعر الصرف" }]}
        >
          <InputNumber
            size="large"
            placeholder="أدخل سعر الصرف"
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item
          label="عملة افتراضية"
          name="IsDefualte"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading} block>
            تعديل
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditCurrency;
