import React, { useState } from "react";
import { Button, Modal, message } from "antd";
import Heading from "../../../Components/Heading/Heading";
import ServerError from "../../../Components/utils/ServerError";
import CarsTable from "./CarsTable";
import AddCarModal from "./AddCarModal";
import EditCarModal from "./EditCarModal";
import { ExclamationCircleFilled } from "@ant-design/icons";

import {
  useGetCarsQuery,
  useAddCarMutation,
  useEditCarMutation,
  useDeleteCarMutation,
  primaryBaseUrl,
} from "../../../redux/Service/api";

const { confirm } = Modal;

const Cars: React.FC = () => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingCar, setEditingCar] = useState<any>(null);

  const {
    data: carsData,
    isError,
    isLoading: isCarsLoading,
  } = useGetCarsQuery(undefined);

  const cars = carsData?.ResponseObject ?? [];

  const [addCar, { isLoading: isAdding }] = useAddCarMutation();
  const [editCar, { isLoading: isEditing }] = useEditCarMutation();
  const [deleteCar, { isLoading: delIsLoading }] = useDeleteCarMutation();

  const handleAddCar = async (formData: FormData) => {
    try {
      await addCar(formData);
      message.success("تمت إضافة السيارة بنجاح");
      setIsAddModalOpen(false);
    } catch {
      message.error("فشل في إضافة السيارة");
    }
  };

  const handleEditCar = async (formData: FormData) => {
    try {
      await editCar(formData);
      message.success("تم تعديل السيارة بنجاح");
      setIsEditModalOpen(false);
    } catch {
      message.error("فشل في تعديل السيارة");
    }
  };

  const handleDeleteCar = async (id: number) => {
    confirm({
      title: "هل أنت متأكد أنك تريد حذف هذه السيارة؟",
      icon: <ExclamationCircleFilled />,
      content: "بالضغط على موافق سيتم حذف السيارة بشكل دائم.",
      async onOk() {
        try {
          await deleteCar(id);
          message.success("تم حذف السيارة بنجاح");
        } catch (error) {
          console.error(error);
          message.error("فشل في حذف السيارة");
        }
      },
    });
  };

  return (
    <section className="p-4 section_padding mt-0 pt-0 relative">
      <Heading heading="السيارات" />

      {isError && <ServerError />}

      <div className="text-center my-3">
        <Button
          // className="btn btn-primary"
          type="primary"
          onClick={() => setIsAddModalOpen(true)}
        >
          إضافة سيارة
        </Button>
      </div>

      <CarsTable
        cars={cars}
        isLoading={isCarsLoading || delIsLoading}
        onEdit={(car) => {
          setEditingCar(car);
          setIsEditModalOpen(true);
        }}
        onDelete={handleDeleteCar}
        primaryBaseUrl={primaryBaseUrl}
      />

      <AddCarModal
        isOpen={isAddModalOpen}
        isLoading={isAdding}
        onClose={() => setIsAddModalOpen(false)}
        onAdd={handleAddCar}
      />

      <EditCarModal
        isOpen={isEditModalOpen}
        isLoading={isEditing}
        onClose={() => {
          setIsEditModalOpen(false);
        }}
        onEdit={handleEditCar}
        initialValues={editingCar}
      />
    </section>
  );
};

export default Cars;
