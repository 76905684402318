import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";

interface EditCarModalProps {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onEdit: (formData: FormData) => void;
  initialValues: any;
}

const EditCarModal: React.FC<EditCarModalProps> = ({
  isOpen,
  isLoading,
  onClose,
  onEdit,
  initialValues,
}) => {
  const [form] = Form.useForm();
  const [file, setFile] = useState<any>(null);

  const handleFileChange = (fileList: any) => {
    if (fileList.length > 0) {
      setFile(fileList[0].originFileObj);
    } else {
      setFile(null);
    }
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const { CarNumber, Modal, Marka, CarName, Price } = values;

      const formData = new FormData();
      formData.append("CarID", initialValues?.CarID.toString());
      formData.append("CarNumber", CarNumber);
      formData.append("Modal", Modal);
      formData.append("Marka", Marka);
      formData.append("CarName", CarName);
      formData.append("Price", Price);

      if (file) {
        formData.append("CarImageName", file);
      }

      onEdit(formData);
      form.resetFields();
      setFile(null);
    } catch (error) {
      console.error("خطأ أثناء تعديل السيارة:", error);
      message.error("يرجى تعبئة الحقول المطلوبة.");
    }
  };

  return (
    <Modal
      title="تعديل السيارة"
      centered
      open={isOpen}
      onCancel={onClose}
      footer={null}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="رقم السيارة"
          name="CarNumber"
          rules={[{ required: true, message: "يرجى إدخال رقم السيارة" }]}
        >
          <Input placeholder="أدخل رقم السيارة" />
        </Form.Item>
        <Form.Item
          label="الموديل"
          name="Modal"
          rules={[{ required: true, message: "يرجى إدخال الموديل" }]}
        >
          <Input placeholder="أدخل الموديل" />
        </Form.Item>
        <Form.Item
          label="الماركة"
          name="Marka"
          rules={[{ required: true, message: "يرجى إدخال الماركة" }]}
        >
          <Input placeholder="أدخل الماركة" />
        </Form.Item>
        <Form.Item
          label="اسم السيارة"
          name="CarName"
          rules={[{ required: true, message: "يرجى إدخال اسم السيارة" }]}
        >
          <Input placeholder="أدخل اسم السيارة" />
        </Form.Item>
        <Form.Item
          label="السعر"
          name="Price"
          rules={[{ required: true, message: "يرجى إدخال السعر" }]}
        >
          <Input type="number" placeholder="أدخل السعر" />
        </Form.Item>
        <Form.Item label="تعديل الصورة">
          <Upload
            customRequest={({ file, onSuccess }) => {
              onSuccess?.("ok");
            }}
            onChange={({ fileList }) => handleFileChange(fileList)}
            listType="picture"
            maxCount={1}
          >
            <Button icon={<UploadOutlined />}>تعديل الملف</Button>
          </Upload>
        </Form.Item>
        <Button type="primary" onClick={handleSubmit} loading={isLoading}>
          تعديل
        </Button>
      </Form>
    </Modal>
  );
};

export default EditCarModal;
