import { Button, Modal, Form, Input, DatePicker, message } from "antd";
import { useEditCustomerMutation } from "../../../redux/Service/api";
import dayjs from "dayjs";
import { useEffect } from "react";

const EditClient = ({
  openModal,
  setOpenModal,
  entity,
}: {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  entity: any;
}) => {
  const [form] = Form.useForm();

  const [editCustomer, { isLoading }] = useEditCustomerMutation();

  useEffect(() => {
    form.setFieldsValue({
      CustomerName: entity?.CustomerName || "",
      Address: entity?.Address || "",
      Phone: entity?.Phone || "",
      Mobile: entity?.Mobile || "",
      Email: entity?.Email || "",
      FirstBalDate: entity?.FirstBalDate ? dayjs(entity.FirstBalDate) : null,
    });
  }, [entity]);

  const handleEditCustomer = async () => {
    try {
      const values = await form.validateFields();
      const customerPayload = {
        CustomerId: entity.CustomerID,
        CustomerName: values.CustomerName,
        Address: values.Address,
        Phone: values.Phone,
        Mobile: values.Mobile,
        Email: values.Email,
        FirstBalDate: values.FirstBalDate.format("YYYY-MM-DDTHH:mm:ss"),
      };

      await editCustomer(customerPayload);
      message.success("تم تعديل بيانات العميل بنجاح");
      form.resetFields();
      setOpenModal(false);
    } catch (error) {
      console.error("خطأ أثناء تعديل بيانات العميل:", error);
      message.error("فشل في تعديل بيانات العميل. يرجى المحاولة مرة أخرى.");
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setOpenModal(false);
  };

  return (
    <Modal
      title="تعديل بيانات العميل"
      centered
      open={openModal}
      onCancel={handleCancel}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        name="edit_customer_form"
        onFinish={handleEditCustomer}
      >
        <Form.Item
          label="اسم العميل"
          name="CustomerName"
          rules={[{ required: true, message: "يرجى إدخال اسم العميل" }]}
        >
          <Input size="large" placeholder="أدخل اسم العميل" />
        </Form.Item>

        <Form.Item
          label="العنوان"
          name="Address"
          rules={[{ required: true, message: "يرجى إدخال العنوان" }]}
        >
          <Input size="large" placeholder="أدخل العنوان" />
        </Form.Item>

        <Form.Item label="الهاتف" name="Phone">
          <Input size="large" placeholder="أدخل رقم الهاتف" />
        </Form.Item>

        <Form.Item label="الجوال" name="Mobile">
          <Input size="large" placeholder="أدخل رقم الجوال" />
        </Form.Item>

        <Form.Item
          label="البريد الإلكتروني"
          name="Email"
          rules={[{ type: "email", message: "يرجى إدخال بريد إلكتروني صحيح" }]}
        >
          <Input size="large" placeholder="أدخل البريد الإلكتروني" />
        </Form.Item>

        <Form.Item
          label="تاريخ الرصيد الأولي"
          name="FirstBalDate"
          rules={[{ required: true, message: "يرجى اختيار تاريخ" }]}
        >
          <DatePicker
            size="large"
            className="w-full"
            placeholder="اختر التاريخ"
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            className="w-full"
          >
            تعديل
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditClient;
