import React, { useState } from "react";
import { DeleteFilled } from "@ant-design/icons";
import { Button, Modal, Table, message } from "antd";
import {
  useGetTripTypesQuery,
  useDeleteTripTypeMutation,
  primaryBaseUrl,
} from "../../../redux/Service/api";
import { ExclamationCircleFilled, EditFilled } from "@ant-design/icons";
import Heading from "../../../Components/Heading/Heading";
import ServerError from "../../../Components/utils/ServerError";
import AddType from "./AddType";
import EditType from "./EditType";
import DataTable from "../../../Components/DataTable";

const { confirm } = Modal;

const TripTypes: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);
  const [entityForEdit, setEntityForEdit] = useState<any>(null);

  const [openEditModal, setOpenEditModal] = useState(false);

  const [deleteTripType, { isLoading: delIsLoading }] =
    useDeleteTripTypeMutation();

  const {
    data: tripTypesData,
    isError: isTripTypesError,
    isLoading: isTripTypesLoading,
  } = useGetTripTypesQuery(undefined);

  const tripTypes: any = tripTypesData?.ResponseObject ?? [];

  const handleDeleteTripType = (id: number) => {
    confirm({
      title: "هل أنت متأكد أنك تريد حذف هذا النوع من الرحلات؟",
      icon: <ExclamationCircleFilled />,
      content: "بالضغط على موافق سيتم حذف نوع الخدمة بشكل دائم.",
      async onOk() {
        try {
          await deleteTripType(id);
          message.success("تم حذف نوع الخدمة بنجاح");
        } catch (error) {
          console.error(error);
          message.error("فشل في حذف نوع الخدمة.");
        }
      },
    });
  };

  const columns = [
    {
      title: "رقم نوع الخدمة",
      dataIndex: "ServiceTypeID",
      key: "ServiceTypeID",
      searchable: true,
    },
    {
      title: "الصورة",
      dataIndex: "ServiceTypeImageName",
      key: "ServiceTypeImageName",
      render: (image: string) =>
        image ? (
          <img
            src={primaryBaseUrl + image}
            alt="TripType"
            className="w-20 h-20 object-cover"
          />
        ) : (
          "لا توجد صورة"
        ),
    },
    {
      title: "اسم نوع الخدمة",
      dataIndex: "ServiceTypeName",
      key: "ServiceTypeName",
      searchable: true,
    },
    {
      title: "الإجراءات",
      key: "actions",
      render: (_: any, tripType: any) => (
        <div className="flex gap-2">
          <Button
            type="text"
            icon={<EditFilled />}
            onClick={() => {
              setEntityForEdit(tripType);
              setOpenEditModal(true);
            }}
          />
          <Button
            type="text"
            icon={<DeleteFilled />}
            danger
            onClick={() => handleDeleteTripType(tripType.ServiceTypeID)}
          />
        </div>
      ),
    },
  ];

  return (
    <section className="p-4 section_padding mt-0 pt-0 relative">
      <Heading heading="أنواع الخدمات" />

      {isTripTypesError && <ServerError />}

      <div className="text-center my-3">
        <Button
          className="w-[150px]"
          type="primary"
          onClick={() => setOpenModal(true)}
        >
          إضافة نوع الخدمة
        </Button>
      </div>

      <div className="mx-auto">
        <DataTable
          columns={columns}
          data={tripTypes}
          loading={isTripTypesLoading || delIsLoading}
        />
      </div>
      <AddType openModal={openModal} setOpenModal={setOpenModal} />
      <EditType
        openModal={openEditModal}
        setOpenModal={setOpenEditModal}
        entity={entityForEdit}
      />
    </section>
  );
};

export default TripTypes;
