import { Button, Form, Input, Modal, Select, Table, message } from "antd";
import {
  useAddDriverMutation,
  useGetCarsQuery,
} from "../../../redux/Service/api";

const { Option } = Select;

const AddDriver = ({ setOpenModal, openModal }: any) => {
  const [form] = Form.useForm();

  const {
    data: carsData,
    isError: isCarsError,
    isLoading: isCarsLoading,
  } = useGetCarsQuery(undefined);
  const cars = carsData?.ResponseObject ?? [];

  const [addDriver, { isLoading }] = useAddDriverMutation();

  const handleAddDriver = async (values: any) => {
    try {
      await addDriver(values);
      message.success("تمت إضافة السائق بنجاح");
      form.resetFields();
      setOpenModal(false);
    } catch (error) {
      console.error("خطأ أثناء إضافة السائق:", error);
      message.error("فشل في إضافة السائق. يرجى المحاولة مرة أخرى.");
    }
  };

  return (
    <Modal
      title="إضافة سائق"
      centered
      open={openModal}
      onCancel={() => {
        form.resetFields();
        setOpenModal(false);
      }}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleAddDriver}
        autoComplete="off"
      >
        <Form.Item
          label="اسم السائق"
          name="DriverName"
          rules={[{ required: true, message: "يرجى إدخال اسم السائق" }]}
        >
          <Input size="large" placeholder="أدخل اسم السائق" />
        </Form.Item>

        <Form.Item
          label="العنوان"
          name="Address"
          rules={[{ required: true, message: "يرجى إدخال العنوان" }]}
        >
          <Input size="large" placeholder="أدخل العنوان" />
        </Form.Item>

        <Form.Item
          label="رقم الهاتف"
          name="Phone"
          rules={[
            { required: true, message: "يرجى إدخال رقم الهاتف" },
            { pattern: /^[0-9]+$/, message: "يجب أن يحتوي على أرقام فقط" },
          ]}
        >
          <Input size="large" placeholder="أدخل رقم الهاتف" />
        </Form.Item>

        <Form.Item
          label="رقم السيارة"
          name="CarId"
          rules={[{ required: true, message: "يرجى اختيار رقم السيارة" }]}
        >
          {isCarsError ? (
            <div className="text-red-500">
              فشل في تحميل السيارات. يرجى المحاولة لاحقًا.
            </div>
          ) : (
            <Select
              size="large"
              placeholder="اختر رقم السيارة"
              loading={isCarsLoading}
            >
              {cars.map((car: any) => (
                <Option key={car.CarID} value={car.CarID}>
                  {car.CarNumber}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading} block>
            إضافة
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddDriver;
