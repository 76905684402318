import React, { useEffect } from "react";
import { Button, DatePicker, Form, Input, Select, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import Heading from "../../../Components/Heading/Heading";
import {
  useAddReservationCollectionMutation,
  useGetBranchesQuery,
  useGetCurrenciesQuery,
  useGetCustomersQuery,
  useGetSellersQuery,
  useLazyGetCustomerReservationsQuery,
} from "../../../redux/Service/api";
import CustomerReservationsDetails from "./CustomerReservationsDetails";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { removeReservationData } from "../../../redux/Slices/authSlice";

const { Option } = Select;

export const paymentMethods = [
  { value: 0, label: "نقدي" },
  { value: 1, label: "انستاباي" },
  { value: 2, label: "محفظة" },
  { value: 3, label: "تحويل بنكي" },
];

const CollectReservation: React.FC = () => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const reservationData = useSelector(
    (state: any) => state.auth.reservationData
  );

  const { data: branchesData } = useGetBranchesQuery(undefined);
  const { data: sellersData } = useGetSellersQuery(undefined);
  const { data: customersData } = useGetCustomersQuery(undefined);

  const [addReservationCollection, { isLoading }] =
    useAddReservationCollectionMutation();

  const {
    data: currenciesData,
    isError,
    isLoading: isCurrenciesLoading,
  } = useGetCurrenciesQuery(undefined);

  const currencies: any[] = currenciesData?.ResponseObject ?? [];

  const branches = branchesData?.ResponseObject || [];
  const sellers = sellersData?.ResponseObject || [];
  const customers = customersData?.ResponseObject || [];

  const [getData, { data: customerReservationsData, isFetching }] =
    useLazyGetCustomerReservationsQuery();
  const customerReservations = customerReservationsData?.ResponseObject || [];

  useEffect(() => {
    if (reservationData) {
      console.log("reservationData", reservationData);

      form.setFieldsValue({
        BranchId: reservationData.BranchID,
        CustomerId: reservationData.CustomerID,
        ReserveId: reservationData.ReserveID,
        DocDate: dayjs(reservationData.ReserveDate),
        Value: reservationData.Total - reservationData.TotalPayed,
        Notes: reservationData.Notes,
        CurrId: reservationData.CurrId,
      });
    }
    return () => {
      dispatch(removeReservationData());
    };
  }, []);

  const onFinish = async () => {
    try {
      const values = await form.validateFields();
      const requestBody = {
        BranchId: values.BranchId,
        DocDate: values.DocDate.format("YYYY-MM-DDT00:00:00"),
        Notes: values.Notes,
        FinCashDets: [
          {
            SideType: 0,
            SideId: values.CustomerId,
            Value: values.Value,
            ValueTafqit: values.ValueTafqit,
            PayType: values.PayType,
            CurrId: values.CurrId,
            CurrRate: 1,
            SafeType: 0,
            ReserveId: values.ReserveId,
            // SafeId: 1,
            SellerId: values.SellerId,
            // CostID: "",
            Notes: values.DetailNotes,
          },
        ],
      };

      const response = await addReservationCollection(requestBody);
      if (response?.data) {
        message.success("تم تحصيل الحجز بنجاح");
        form.resetFields();
      }
    } catch (error) {
      console.error("Error collecting reservation:", error);
      message.error("Failed to collect reservation. Please try again.");
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error("Failed:", errorInfo);
    message.error("Please complete all required fields.");
  };

  return (
    <div>
      <Heading heading="تحصيل حجز" />
      <div className="container grid grid-cols-1 gap-0">
        <Form
          form={form}
          name="collectReservation"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="form-grid"
        >
          <Form.Item
            label="العميل"
            name="CustomerId"
            rules={[{ required: true, message: "يرجى اختيار العميل" }]}
          >
            <Select
              size="large"
              placeholder="اختر العميل"
              showSearch
              onChange={(e) => {
                getData(e);
              }}
            >
              {customers?.map((customer: any) => (
                <Option key={customer.CustomerID} value={customer.CustomerID}>
                  {customer.CustomerName}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="رقم الحجز"
            name="ReserveId"
            rules={[{ required: true, message: "يرجى اختيار رقم الحجز" }]}
            tooltip="الرجاء اختيار العميل أولاً"
          >
            <Select
              disabled={!form.getFieldValue("CustomerId")}
              size="large"
              placeholder="اختر رقم الحجز"
              showSearch
              className="min-w-[200px]"
              loading={isFetching}
            >
              {customerReservations?.map((reservation: any) => (
                <Option
                  key={reservation.ReserveID}
                  value={reservation.ReserveID}
                >
                  {reservation.ReserveID}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="الفرع"
            name="BranchId"
            rules={[{ required: true, message: "يرجى اختيار الفرع" }]}
          >
            <Select size="large" placeholder="اختر الفرع">
              {branches.map((branch: any) => (
                <Option key={branch.BranchID} value={branch.BranchID}>
                  {branch.BranchName}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="تاريخ المستند"
            name="DocDate"
            rules={[{ required: true, message: "يرجى اختيار تاريخ المستند" }]}
          >
            <DatePicker size="large" className="w-full" />
          </Form.Item>

          <Form.Item
            label="قيمة التحصيل"
            name="Value"
            rules={[{ required: true, message: "يرجى إدخال القيمة" }]}
          >
            <Input size="large" type="number" />
          </Form.Item>

          <Form.Item
            label="القيمة التفقيطية"
            name="ValueTafqit"
            rules={[{ required: true, message: "يرجى إدخال القيمة التفقيطية" }]}
          >
            <Input size="large" placeholder="" />
          </Form.Item>

          <Form.Item
            label="مندوب تحصيل"
            name="SellerId"
            rules={[{ required: true, message: "يرجى اختيار مندوب تحصيل" }]}
          >
            <Select size="large" placeholder="اختر مندوب تحصيل">
              {sellers.map((seller: any) => (
                <Option key={seller.SellerID} value={seller.SellerID}>
                  {seller.SellerName}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {/* add new field for طريقة الدفع */}
          <Form.Item
            label="طريقة الدفع"
            name="PayType"
            rules={[{ required: true, message: "يرجى اختيار طريقة الدفع" }]}
            // initialValue={0}
          >
            <Select size="large" placeholder="اختر طريقة الدفع">
              {paymentMethods.map((method) => (
                <Option key={method.value} value={method.value}>
                  {method.label}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="العملة"
            name="CurrId"
            rules={[{ required: true, message: "يرجى إدخال العملة" }]}
          >
            <Select
              size="large"
              placeholder="اختر العملة"
              showSearch
              filterOption={(input, option: any) =>
                (option?.children).toLowerCase().includes(input.toLowerCase())
              }
            >
              {currencies.map((curr: any) => (
                <Option key={curr.CurrID} value={curr.CurrID}>
                  {curr.CurrName}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="ملاحظات" name="Notes">
            <TextArea size="large" rows={2} />
          </Form.Item>

          <Form.Item
            label="ملاحظات تفصيلية"
            name="DetailNotes"
            className="col-span-2"
          >
            <TextArea size="large" rows={2} />
          </Form.Item>

          <div className="flex items-center">
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              loading={isLoading}
            >
              حفظ
            </Button>
          </div>
        </Form>

        <CustomerReservationsDetails customers={customers} />
      </div>
    </div>
  );
};

export default CollectReservation;
