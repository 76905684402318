import React from "react";
import { Button, Table } from "antd";
import { DeleteFilled, EditFilled } from "@ant-design/icons";

interface Car {
  CarID: number;
  CarNumber: string;
  Modal: string;
  Marka: string;
  CarName: string;
  Price: number;
  CarImageName?: string;
}

interface CarsTableProps {
  cars: Car[];
  isLoading: boolean;
  onEdit: (car: Car) => void;
  onDelete: (id: number) => void;
  primaryBaseUrl: string;
}

const CarsTable: React.FC<CarsTableProps> = ({
  cars,
  isLoading,
  onEdit,
  onDelete,
  primaryBaseUrl,
}) => {
  const columns = [
    {
      title: "رقم السيارة",
      dataIndex: "CarNumber",
      key: "CarNumber",
    },
    {
      title: "الموديل",
      dataIndex: "Modal",
      key: "Modal",
    },
    {
      title: "الماركة",
      dataIndex: "Marka",
      key: "Marka",
    },
    {
      title: "اسم السيارة",
      dataIndex: "CarName",
      key: "CarName",
    },
    {
      title: "السعر",
      dataIndex: "Price",
      key: "Price",
    },
    {
      title: "الصورة",
      dataIndex: "CarImageName",
      key: "CarImageName",
      render: (image: string) =>
        image ? (
          <img
            src={primaryBaseUrl + image}
            alt="CarImageName"
            className="w-16 h-16 object-cover rounded"
          />
        ) : (
          "لا توجد صورة"
        ),
    },
    {
      title: "الإجراءات",
      key: "actions",
      render: (_: any, car: Car) => (
        <div className="flex gap-2">
          <Button
            type="text"
            icon={<EditFilled />}
            onClick={() => onEdit(car)}
          />
          <Button
            type="text"
            icon={<DeleteFilled />}
            danger
            onClick={() => onDelete(car.CarID)}
          />
        </div>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={cars}
      rowKey="CarID"
      loading={isLoading}
      pagination={{ pageSize: 5 }}
    />
  );
};

export default CarsTable;
