import { useGetAboutUsQuery } from "../../redux/Service/api";

const VideoSection: React.FC = () => {
  const {
    data: configData,
    isLoading: isConfigLoading,
    isError: isConfigError,
  } = useGetAboutUsQuery(undefined);

  const videoLink = configData?.ResponseObject[0]?.Addrss ?? "";

  const getEmbedUrl = (url: string) => {
    if (!url) return "";

    try {
      const youtubeRegex =
        /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)/;
      const shortYoutubeRegex = /(?:https?:\/\/)?youtu\.be\/([^?]+)/;
      const facebookEmbedRegex = /facebook\.com\/plugins\/video\.php\?(.+)/;

      if (youtubeRegex.test(url)) {
        const videoId = url.match(youtubeRegex)?.[1];
        return `https://www.youtube.com/embed/${videoId}`;
      }

      if (shortYoutubeRegex.test(url)) {
        const videoId = url.match(shortYoutubeRegex)?.[1];
        return `https://www.youtube.com/embed/${videoId}`;
      }

      if (facebookEmbedRegex.test(url)) {
        return url; // The URL is already in the correct embed format
      }

      return ""; // Unsupported format
    } catch (error) {
      console.error("Invalid video URL:", error);
      return "";
    }
  };

  const embedUrl = getEmbedUrl(videoLink);

  return (
    <section className="flex justify-center items-center py-6 h-fit bg-gray-50">
      {isConfigLoading ? (
        <p className="text-center text-gray-600">Loading video...</p>
      ) : isConfigError ? (
        <></>
      ) : (
        embedUrl && (
          <iframe
            src={videoLink}
            title="Embedded Video"
            frameBorder="0"
            allowFullScreen
            className="rounded-lg h-[220px] sm:h-[320px] w-[560px] lg:w-[840px] lg:h-[480px]"
          ></iframe>
        )
      )}
    </section>
  );
};

export default VideoSection;
