import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "./styles.css";

// import required modules
import { Autoplay, EffectFade, Pagination } from "swiper/modules";

// import image

import Slider1 from "../../assets/Slider1.png";
import Slider2 from "../../assets/Slider2.png";
import Slider3 from "../../assets/Slider3.png";

function OurOffers() {
  return (
    <div className="carousel container my-16 !h-[25vh] sm:!h-[30vh] lg:!h-[40vh]">
      <Swiper
        spaceBetween={30}
        effect={"fade"}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, EffectFade, Pagination]}
        className="mySwiper w-full h-full"
      >
        <SwiperSlide className="rounded-lg">
          <img
            src={Slider1}
            alt="Image Slider"
            className="rounded-lg block w-full h-full object-cover"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={Slider2}
            alt="Image Slider"
            className="rounded-lg block w-full h-full object-cover"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={Slider3}
            alt="Image Slider"
            className="rounded-lg block w-full h-full object-cover"
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default OurOffers;
