import { Button, Steps } from "antd";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBus,
  faCircleLeft,
  faLocationDot,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import OurFeatures from "../../Sections/OurFeatures";
import OurOffers from "../../Sections/OurOffers";
import Slider from "../../Sections/Slider";

const seatLayout: (number | null)[][] = [
  [1, 2, null, null, 3, 4],
  [5, 6, null, null, 7, 8],
  [9, 10, null, null, 11, 12],
  [null, null, null, null, 1, 2],
  [13, 14, null, null, 15, 16],
  [17, 18, null, null, 19, 20],
  [21, 22, null, null, 23, 24],
  [25, 26, null, null, 27, 28],
  [29, 30, null, null, 31, 32],
  [33, 34, null, null, 35, 36],
  [37, 38, null, null, 39, 40],
  [41, 42, 43, 44, 45, 46], // أي صفوف إضافية
];

function Bus() {
  const [selectedSeats, setSelectedSeats] = useState<number[]>([]);

  const toggleSeat = (seat: number) => {
    setSelectedSeats((prev) =>
      prev.includes(seat) ? prev.filter((s) => s !== seat) : [...prev, seat]
    );
  };

  return (
    <>
      <Slider />
      {/*  */}
      <div className="container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 my-10">
        <div className="grid grid-cols-1 gap-5 h-fit">
          <div className="grid grid-cols-2 gap-3 justify-center items-center my-4 text-center">
            <div className="">
              <FontAwesomeIcon
                className="text-6xl text-[#8a4fea]"
                icon={faUserTie}
              />
            </div>
            <div className="text-[#1AA60E] font-semibold text-xl">
              <h4>Mohamed buomy</h4>
              <h4>01018007073</h4>
            </div>
          </div>
          <Steps
            className="!flex-row text-sm"
            size="small"
            items={[
              {
                title: "رحله من دمياط",
                status: "finish",
                icon: <FontAwesomeIcon icon={faCircleLeft} />,
              },
              {
                title: "وصولا الى رمسيس",
                status: "finish",
                icon: <FontAwesomeIcon icon={faLocationDot} />,
              },
            ]}
          />
          <div className="grid grid-cols-3 gap-2 text-center items-center">
            <h4>السبت 4:30 مساءاً</h4>
            <div className="">
              <FontAwesomeIcon
                className="text-6xl text-[#262895]"
                icon={faBus}
              />
            </div>
            <h4>السبت 7:30 مساءاً</h4>
          </div>
        </div>
        <div className="">
          <h1 className="rounded text-center mb-3 text-white bg-[#270D5F] w-fit mx-auto px-6 py-1">
            اختار الكرسي
          </h1>
          <div className="grid grid-cols-6 p-5 rounded-md border-2 gap-x-2">
            {seatLayout.map((row, rowIndex) =>
              row.map((seat, index) =>
                seat ? (
                  <Button
                    key={`${rowIndex}-${index}`}
                    className={`rounded-md font-semibold m-0 ${
                      selectedSeats.includes(seat)
                        ? "bg-[#6b37fa] text-white hover:!bg-[#7545f7] hover:!text-white"
                        : "bg-[#2A92CD] text-white hover:!bg-[#40b7fc] hover:!text-white"
                    }`}
                    onClick={() => toggleSeat(seat)}
                    disabled={
                      selectedSeats.length >= 2 && !selectedSeats.includes(seat)
                    }
                  >
                    {seat}
                  </Button>
                ) : (
                  <div
                    key={`${rowIndex}-${index}`}
                    className="invisible rounded-md h-10 w-10"
                  />
                )
              )
            )}
          </div>
        </div>
        <div className="border-2 h-fit rounded-md py-3 grid shadow-md text-center font-semibold text-[#085E8E]">
          <h4 className="border-b-2 pb-3">2 مسافر</h4>
          <div className="grid px-5 gap-5">
            <h4>الثلاثاء 24 سبتمبر دمياط - المطار</h4>
            <h4>عبد المنعم رياض - الغردقة</h4>
            <h4>كرسى رقم 18</h4>
            <h4>سعر التذكرة: 850</h4>
            <h4>الأجمالي: 555</h4>
            <h4>شاملة الضرائب</h4>
            <button
              type="button"
              className="px-5 py-2.5 rounded-sm text-white text-sm tracking-wider font-medium border border-current outline-none bg-green-700 hover:bg-green-800 active:bg-green-700"
            >
              دفع الان
            </button>
          </div>
        </div>
      </div>
      {/*  */}
      <OurFeatures />
      <OurOffers />
    </>
  );
}

export default Bus;
