import React, { useState } from "react";
import { DeleteFilled } from "@ant-design/icons";
import { Button, Modal, Table, message } from "antd";
import {
  useGetCustomersQuery,
  useDeleteCustomerMutation,
} from "../../../redux/Service/api";
import { ExclamationCircleFilled, EditFilled } from "@ant-design/icons";
import Heading from "../../../Components/Heading/Heading";
import ServerError from "../../../Components/utils/ServerError";
import dayjs from "dayjs";
import AddClient from "./AddClient";
import EditClient from "./EditClient";
import DataTable from "../../../Components/DataTable";

interface Customer {
  CustomerID: number;
  CustomerName: string;
  Address: string;
  Phone: string;
  Mobile: string;
  Email: string;
  FirstBalDate: string;
}

const { confirm } = Modal;

const Customer: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);

  const [openEditModal, setOpenEditModal] = useState(false);
  const [entityForEdit, setEntityForEdit] = useState<any>(null);

  const [deleteCustomer, { isLoading: delIsLoading }] =
    useDeleteCustomerMutation();

  const {
    data: customersData,
    isError: isCustomersError,
    isLoading: isCustomersLoading,
  } = useGetCustomersQuery(undefined);

  const customers: Customer[] = customersData?.ResponseObject ?? [];

  const handleDeleteCustomer = (id: number) => {
    confirm({
      title: "هل أنت متأكد أنك تريد حذف هذا العميل؟",
      icon: <ExclamationCircleFilled />,
      content: "بالضغط على موافق سيتم حذف العميل بشكل دائم.",
      async onOk() {
        try {
          await deleteCustomer(id);
          message.success("تم حذف العميل بنجاح");
        } catch (error) {
          console.error(error);
          message.error("فشل في حذف العميل.");
        }
      },
    });
  };

  const columns = [
    {
      title: "اسم العميل",
      dataIndex: "CustomerName",
      key: "CustomerName",
      searchable: true,
    },
    {
      title: "العنوان",
      dataIndex: "Address",
      key: "Address",
      searchable: true,
    },
    {
      title: "الهاتف",
      dataIndex: "Phone",
      key: "Phone",
      searchable: true,
    },
    {
      title: "الجوال",
      dataIndex: "Mobile",
      key: "Mobile",
      searchable: true,
    },
    {
      title: "البريد الإلكتروني",
      dataIndex: "Email",
      key: "Email",
      searchable: true,
    },
    {
      title: "تاريخ الرصيد الأولي",
      dataIndex: "FirstBalDate",
      key: "FirstBalDate",
      render: (date: string) => dayjs(date).format("YYYY-MM-DD"),
    },
    {
      title: "الإجراءات",
      key: "actions",
      render: (_: any, customer: Customer) => (
        <div className="flex gap-2">
          <Button
            type="text"
            icon={<EditFilled />}
            onClick={() => {
              setEntityForEdit(customer);
              setOpenEditModal(true);
            }}
          />
          <Button
            type="text"
            icon={<DeleteFilled />}
            danger
            onClick={() => handleDeleteCustomer(customer.CustomerID)}
          />
        </div>
      ),
    },
  ];

  return (
    <section className="p-4 section_padding mt-0 pt-0 relative">
      <Heading heading="العملاء" />
      {isCustomersError && <ServerError />}
      <div className="text-center my-3">
        <Button
          className="w-[150px]"
          type="primary"
          onClick={() => setOpenModal(true)}
        >
          إضافة عميل
        </Button>
      </div>
      <div className="mx-auto">
        <DataTable
          columns={columns}
          data={customers}
          loading={isCustomersLoading || delIsLoading}
        />
      </div>
      <AddClient openModal={openModal} setOpenModal={setOpenModal} />{" "}
      <EditClient
        openModal={openEditModal}
        setOpenModal={setOpenEditModal}
        entity={entityForEdit}
      />
    </section>
  );
};

export default Customer;
