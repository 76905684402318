import React, { useState } from "react";
import { DeleteFilled, PlusOutlined } from "@ant-design/icons";
import { Button, Modal, Table, Form, Input, message } from "antd";
import {
  useGetUsersQuery,
  useAddUserMutation,
  useDeleteUserMutation,
} from "../../../redux/Service/api";
import { ExclamationCircleFilled } from "@ant-design/icons";
import Heading from "../../../Components/Heading/Heading";
import ServerError from "../../../Components/utils/ServerError";
import DataTable from "../../../Components/DataTable";

interface User {
  UserID: number;
  UserName: string;
  UserRole: string;
  Email: string;
}

const { confirm } = Modal;

const SystemUsers: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);
  const [form] = Form.useForm();

  const [addUser, { isLoading }] = useAddUserMutation();
  const [deleteUser, { isLoading: delIsLoading }] = useDeleteUserMutation();

  const {
    data: usersData,
    isError: isUsersError,
    isLoading: isUsersLoading,
  } = useGetUsersQuery(undefined);

  const users: User[] = usersData?.ResponseObject ?? [];

  const handleAddUser = async () => {
    try {
      const values = await form.validateFields();
      const userPayload = {
        UserName: values.UserName,
        UserPassword: values.UserPassword,
        UserRole: parseInt(values.UserRole), // Ensure it's a number
        Email: values.Email,
      };

      await addUser(userPayload);
      message.success("تمت إضافة المستخدم بنجاح");
      form.resetFields();
      setOpenModal(false);
    } catch (error) {
      console.error("خطأ أثناء إضافة المستخدم:", error);
      message.error("فشل في إضافة المستخدم. يرجى المحاولة مرة أخرى.");
    }
  };

  const handleDeleteUser = (id: number) => {
    confirm({
      title: "هل أنت متأكد أنك تريد حذف هذا المستخدم؟",
      icon: <ExclamationCircleFilled />,
      content: "بالضغط على موافق سيتم حذف المستخدم بشكل دائم.",
      async onOk() {
        try {
          await deleteUser(id);
          message.success("تم حذف المستخدم بنجاح");
        } catch (error) {
          console.error(error);
          message.error("فشل في حذف المستخدم.");
        }
      },
    });
  };

  const columns = [
    {
      title: "اسم المستخدم",
      dataIndex: "UserName",
      key: "UserName",
      searchable: true,
    },
    {
      title: "الدور",
      dataIndex: "UserRole",
      key: "UserRole",
      render: (role: string) => (role === "0" ? "مدير" : "مستخدم"),
    },
    {
      title: "البريد الإلكتروني",
      dataIndex: "Email",
      key: "Email",
      searchable: true,
    },
    // {
    //   title: "الإجراءات",
    //   key: "actions",
    //   render: (_: any, user: User) => (
    //     <div className="flex gap-2">
    //       <Button
    //         type="text"
    //         icon={<DeleteFilled />}
    //         danger
    //         onClick={() => handleDeleteUser(user.UserID)}
    //       />
    //     </div>
    //   ),
    // },
  ];

  return (
    <section className="p-4 section_padding mt-0 pt-0 relative">
      <Heading heading="المستخدمون" />

      {isUsersError && <ServerError />}

      <div className="text-center my-3">
        <Button
          className="w-[150px]"
          type="primary"
          onClick={() => setOpenModal(true)}
        >
          إضافة مستخدم
        </Button>
      </div>

      <div className="mx-auto">
        <DataTable
          columns={columns}
          data={users}
          loading={isUsersLoading || delIsLoading}
        />
      </div>

      <Modal
        title="إضافة مستخدم"
        centered
        open={openModal}
        onCancel={() => {
          setOpenModal(false);
          form.resetFields();
        }}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          name="add_user_form"
          onFinish={handleAddUser}
        >
          <Form.Item
            label="اسم المستخدم"
            name="UserName"
            rules={[{ required: true, message: "يرجى إدخال اسم المستخدم" }]}
          >
            <Input size="large" placeholder="أدخل اسم المستخدم" />
          </Form.Item>

          <Form.Item
            label="كلمة المرور"
            name="UserPassword"
            rules={[{ required: true, message: "يرجى إدخال كلمة المرور" }]}
          >
            <Input.Password size="large" placeholder="أدخل كلمة المرور" />
          </Form.Item>

          <Form.Item
            label="الدور"
            name="UserRole"
            rules={[{ required: true, message: "يرجى إدخال الدور" }]}
          >
            <Input size="large" placeholder="0 لمدير، 1 لمستخدم" />
          </Form.Item>

          <Form.Item
            label="البريد الإلكتروني"
            name="Email"
            rules={[
              { required: true, message: "يرجى إدخال البريد الإلكتروني" },
            ]}
          >
            <Input size="large" placeholder="أدخل البريد الإلكتروني" />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              className="w-full"
            >
              إضافة
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </section>
  );
};

export default SystemUsers;
