import React from "react";
import type { FormProps } from "antd";
import {
  Button,
  Checkbox,
  Form,
  DatePicker,
  Select,
  TimePicker,
  Input,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import "./styles.css";
import car1 from "../../assets/car1.png";
import car2 from "../../assets/car2.png";
import OurFeatures from "../../Sections/OurFeatures";
import OurOffers from "../../Sections/OurOffers";
import Slider from "../../Sections/Slider";
type FieldType = {
  username?: string;
  password?: string;
  remember?: string;
};

const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
  console.log("Success:", values);
};

const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (errorInfo) => {
  console.log("Failed:", errorInfo);
};

// start select
const handleChange = (value: string) => {
  console.log(`selected ${value}`);
};
// end select
function Bookings() {
  return (
    <>
      <Slider />
      {/*  */}
      <div className="mt-[70px]">
        <div className="container grid grid-cols-1 lg:grid-cols-2 gap-8 mt-20">
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="grid gap-2 md:gap-0 md:grid-cols-2 bg-PumpkinOrange py-5 px-5 rounded-xl md:h-[500px] CarBooking"
          >
            <Form.Item<FieldType>
              name="remember"
              wrapperCol={{ offset: 4, span: 16 }}
              className="text-center"
            >
              <Checkbox>ذهاب فقط</Checkbox>
            </Form.Item>
            <Form.Item<FieldType>
              name="remember"
              wrapperCol={{ offset: 4, span: 16 }}
              className="text-center"
            >
              <Checkbox>ذهاب وعوده</Checkbox>
            </Form.Item>

            <Form.Item<FieldType>
              label="نوع السياره"
              name="username"
              rules={[{ message: "Please input your username!" }]}
            >
              <Select
                defaultValue="lucy"
                style={{ width: "100%" }}
                onChange={handleChange}
                options={[
                  { value: "jack", label: "Jack" },
                  { value: "lucy", label: "Lucy" },
                  { value: "Yiminghe", label: "yiminghe" },
                  { value: "disabled", label: "Disabled", disabled: true },
                ]}
              />
            </Form.Item>
            <Form.Item<FieldType>
              label="نوع الإيجار"
              name="username"
              rules={[{ message: "Please input your username!" }]}
            >
              <Select
                defaultValue="lucy"
                style={{ width: "100%" }}
                onChange={handleChange}
                options={[
                  { value: "jack", label: "Jack" },
                  { value: "lucy", label: "Lucy" },
                  { value: "Yiminghe", label: "yiminghe" },
                  { value: "disabled", label: "Disabled", disabled: true },
                ]}
              />
            </Form.Item>
            <Form.Item<FieldType>
              label="من"
              name="username"
              rules={[{ message: "Please input your username!" }]}
            >
              <Select
                defaultValue="lucy"
                style={{ width: "100%" }}
                onChange={handleChange}
                options={[
                  { value: "jack", label: "Jack" },
                  { value: "lucy", label: "Lucy" },
                  { value: "Yiminghe", label: "yiminghe" },
                  { value: "disabled", label: "Disabled", disabled: true },
                ]}
              />
            </Form.Item>
            <Form.Item<FieldType>
              label="الى"
              name="username"
              wrapperCol={{ offset: 0, span: 16 }}
              rules={[{ message: "Please input your username!" }]}
            >
              <Select
                defaultValue="lucy"
                style={{ width: "100%" }}
                onChange={handleChange}
                options={[
                  { value: "jack", label: "Jack" },
                  { value: "lucy", label: "Lucy" },
                  { value: "Yiminghe", label: "yiminghe" },
                  { value: "disabled", label: "Disabled", disabled: true },
                ]}
              />
            </Form.Item>

            <Form.Item<FieldType>
              label="ملاحظات"
              name="username"
              labelCol={{ span: 4 }}
              wrapperCol={{ offset: 0, span: 20 }}
              rules={[{ message: "Please input your username!" }]}
              className="col-span-2"
            >
              <TextArea rows={3} />
            </Form.Item>
            <Form.Item<FieldType>
              label="تاريخ الحجز"
              name="username"
              labelCol={{ span: 4 }}
              wrapperCol={{ offset: 0, span: 20 }}
              rules={[{ message: "Please input your username!" }]}
              className="col-span-2"
            >
              <div className="grid gap-3 grid-cols-1 md:grid-cols-2">
                <DatePicker placeholder="ادخل التاريخ" />
                <TimePicker.RangePicker
                  placeholder={["بدء الوقت", "نهاية الوقت"]}
                />
              </div>
            </Form.Item>
            <Form.Item<FieldType>
              label="عدد الافراد   -  الحد الاقصى 3"
              name="username"
              labelCol={{ span: 8 }}
              wrapperCol={{ offset: 0, span: 6 }}
              rules={[{ message: "Please input your username!" }]}
              className="col-span-2"
            >
              <Input />
            </Form.Item>

            <Form.Item className="col-span-2 flex justify-center items-center">
              <Button type="primary" className="font-semibold px-10">
                بحث
              </Button>
            </Form.Item>
          </Form>
          <div className="grid grid-cols-2 md:grid-cols-4 xl:grid-cols-3 gap-5 xl:gap-8 justify-center items-center">
            <div className="grid gap-2 text-center text-black text-sm font-semibold">
              <img
                src={car2}
                className="w-full h-[200px] object-cover"
                alt=""
              />
              <span>تويوتا كرولا رقم 3544 </span>
              <span>السعر 850 </span>
              <button
                type="button"
                className="px-5 py-2.5 rounded-sm text-white text-sm tracking-wider font-medium border border-current outline-none bg-green-700 hover:bg-green-800 active:bg-green-700"
              >
                حجز
              </button>
            </div>
            <div className="grid gap-2 text-center text-black text-sm font-semibold">
              <img
                src={car2}
                className="w-full h-[200px] object-cover"
                alt=""
              />
              <span>تويوتا كرولا رقم 3544 </span>
              <span>السعر 850 </span>
              <button
                type="button"
                className="px-5 py-2.5 rounded-sm text-white text-sm tracking-wider font-medium border border-current outline-none bg-green-700 hover:bg-green-800 active:bg-green-700"
              >
                حجز
              </button>
            </div>
            <div className="grid gap-2 text-center text-black text-sm font-semibold">
              <img
                src={car2}
                className="w-full h-[200px] object-cover"
                alt=""
              />
              <span>تويوتا كرولا رقم 3544 </span>
              <span>السعر 850 </span>
              <button
                type="button"
                className="px-5 py-2.5 rounded-sm text-white text-sm tracking-wider font-medium border border-current outline-none bg-green-700 hover:bg-green-800 active:bg-green-700"
              >
                حجز
              </button>
            </div>
          </div>
          <div className="border-2 rounded-md py-3 grid shadow-md text-center font-semibold text-[#085E8E]">
            <h4 className="border-b-2 pb-3">2 مسافر</h4>
            <div className="grid px-5 gap-3">
              <h4>الثلاثاء 24 سبتمبر دمياط - المطار</h4>
              <h4>سياره كرولا 3554</h4>
              <h4>سعر: 850</h4>
              <h4>الأجمالي: 555</h4>
              <button
                type="button"
                className="px-5 py-2.5 rounded-sm text-white text-sm tracking-wider font-medium border border-current outline-none bg-green-700 hover:bg-green-800 active:bg-green-700"
              >
                دفع الان
              </button>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <OurFeatures />
      <OurOffers />
    </>
  );
}

export default Bookings;
