import React, { useEffect } from "react";
// import PropTypes from "prop-types";
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
  message,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import {
  useAddReservationMutation,
  useGetBranchesQuery,
  useGetCurrenciesQuery,
  useGetCustomersQuery,
  useGetItinerariesQuery,
  useGetServicesQuery,
} from "../../redux/Service/api";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
const { Option } = Select;

const AddReservationModal = ({
  open,
  setOpen,
  serviceId,
  customerId,
  serviceName,
  servicePrice,
  serviceTaxRate,
  ReserveUserID,
  IsTrip,
}: any) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { data: branchesData, isLoading: isBranchesLoading } =
    useGetBranchesQuery(undefined);

  const { data: itinerariesData, isLoading: isItinerariesLoading } =
    useGetItinerariesQuery(undefined);

  const [addReservation, { isLoading: isAdding }] = useAddReservationMutation();

  const branches = branchesData?.ResponseObject || [];
  const itineraries = itinerariesData?.ResponseObject || [];

  const {
    data: currenciesData,
    isError,
    isLoading: isCurrenciesLoading,
  } = useGetCurrenciesQuery(undefined);

  const currencies: any[] = currenciesData?.ResponseObject ?? [];

  const initialValues = {
    CustomerID: customerId,
    ServiceID: serviceId,
    PersonsCount: 1,
    Total: servicePrice,
    ReserveDate: dayjs(new Date()),
  };

  useEffect(() => {
    console.log("initialValues", initialValues);

    form.setFieldsValue(initialValues);
  }, [customerId, serviceName, serviceId, open]);

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      const requestBody = {
        ...values,
        ReserveDate: values.ReserveDate.format("YYYY-MM-DD"),
        ServiceID: values.ServiceID,
        CustomerID: values.CustomerID,
        BranchID: values.BranchID,
        ReserveUserID: ReserveUserID,
        TripNo: values.TripNo,
        TripDate: values.TripDate.format("YYYY-MM-DD"),
        PersonsCount: values.PersonsCount || 1,
        Total: Math.floor(values.Total) || 0,
        Payed: Math.floor(values.Payed) || 0,
        Notes: values.Notes,
        SitsNumbers: values.SitsNumbers || [],
      };

      console.log("requestBody", requestBody);
      // return;

      const response = await addReservation(requestBody).unwrap();
      message.success("تمت انشاء الحجز بنجاح");

      const customerPhone = "+201222214924";
      const messageText = `✅ *تفاصيل الحجز الخاص بك:*
🆔 *رقم الخدمة:* ${requestBody.ServiceID}
👤 *رقم العميل:* ${requestBody.CustomerID}
📅 *تاريخ الطلب:* ${requestBody.ReserveDate}
📆 *تاريخ الخدمة:* ${requestBody.TripDate}
🏢 *الفرع:* ${
        branches.find((branch: any) => branch.BranchID === requestBody.BranchID)
          ?.BranchName
      }
👥 *عدد الأفراد:* ${requestBody.PersonsCount}
💰 *المبلغ الإجمالي:* ${requestBody.Total} ج.م
💵 *المبلغ المدفوع:* ${requestBody.Payed} ج.م
📝 *ملاحظات:* ${requestBody.Notes}`;

      const whatsappUrl = `https://wa.me/${customerPhone}?text=${encodeURIComponent(
        messageText
      )}`;

      // Open WhatsApp message automatically
      window.open(whatsappUrl, "_blank");

      // return;

      form.resetFields();
      setOpen(false);
      navigate("/");
    } catch (error) {
      console.error("Error creating reservation:", error);
      message.error("فشل إنشاء الحجز. حاول مرة أخرى.");
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setOpen(false);
  };

  return (
    <Drawer
      title="حجز خدمة"
      open={open}
      onClose={handleCancel}
      placement="right"
    >
      <Form
        form={form}
        name="bookFlight"
        layout="vertical"
        initialValues={{ PersonsCount: 1, Total: 0, Payed: 0 }}
        onFinish={handleOk}
        // onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="تاريخ الحجز"
          name="ReserveDate"
          rules={[{ required: true, message: "يرجى اختيار تاريخ الحجز" }]}
        >
          <DatePicker size="large" className="w-full" disabled />
        </Form.Item>

        <Form.Item
          label="رقم العميل"
          name="CustomerID"
          rules={[{ required: true, message: "يرجى اختيار العميل" }]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label=" رقم الخدمة"
          name="ServiceID"
          rules={[{ required: true, message: "يرجى اختيار الخدمة" }]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="الفرع"
          name="BranchID"
          rules={[{ required: true, message: "يرجى اختيار الفرع" }]}
        >
          <Select size="large" placeholder="اختر الفرع">
            {branches?.map((branch: any) => (
              <Option key={branch.BranchID} value={branch.BranchID}>
                {branch.BranchName}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {IsTrip && (
          <Form.Item
            label="خط السير"
            name="ItineraryID"
            rules={[{ required: true, message: "يرجى إختيار خط السير" }]}
          >
            <Select size="large" placeholder="اختر خط السير">
              {itineraries?.map((itinerary: any) => (
                <Option
                  key={itinerary.ItineraryID}
                  value={itinerary.ItineraryID}
                >
                  {itinerary.CountryFromName} - {itinerary.CountryToName}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          label="تاريخ الخدمة"
          name="TripDate"
          rules={[{ required: true, message: "يرجى اختيار تاريخ الخدمة" }]}
        >
          <DatePicker size="large" className="w-full" />
        </Form.Item>

        <Form.Item
          label="عدد الأفراد"
          name="PersonsCount"
          rules={[{ required: true, message: "يرجى إدخال عدد الأفراد" }]}
        >
          <Input size="large" type="number" />
        </Form.Item>

        <Form.Item
          label="الإجمالي"
          name="Total"
          rules={[{ required: true, message: "يرجى إدخال القيمة الإجمالية" }]}
        >
          <Input size="large" type="number" disabled />
        </Form.Item>

        <Form.Item
          label="المدفوع"
          name="Payed"
          rules={[{ required: true, message: "يرجى إدخال المدفوع" }]}
        >
          <Input size="large" type="number" />
        </Form.Item>

        <Form.Item
          label="العملة"
          name="CurrId"
          rules={[{ required: true, message: "يرجى إدخال العملة" }]}
        >
          <Select
            size="large"
            placeholder="اختر العملة"
            showSearch
            filterOption={(input, option: any) =>
              (option?.children).toLowerCase().includes(input.toLowerCase())
            }
          >
            {currencies.map((curr: any) => (
              <Option key={curr.CurrID} value={curr.CurrID}>
                {curr.CurrName}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="ملاحظات" name="Notes">
          <TextArea size="large" rows={2} />
        </Form.Item>

        <div className="flex ">
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            loading={isAdding}
          >
            حفظ
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};

export default AddReservationModal;
