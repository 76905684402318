import React, { useState, useRef } from "react";
import { Table, Input, Button, Modal, message } from "antd";
import {
  useGetReservationsQuery,
  useGetCustomersQuery,
  useGetCountriesQuery,
  useGetTripTypesQuery,
  useDeleteReservationMutation,
  useGetServicesQuery,
} from "../../../redux/Service/api";
import dayjs from "dayjs";
import Heading from "../../../Components/Heading/Heading";
import ServerError from "../../../Components/utils/ServerError";
import { SearchOutlined } from "@ant-design/icons";
import type { ColumnType } from "antd/es/table";
import type { InputRef } from "antd";
import Highlighter from "react-highlight-words";
import { DeleteFilled, ExclamationCircleFilled } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { saveReservationData } from "../../../redux/Slices/authSlice";
import { useNavigate } from "react-router-dom";
import { paymentMethods } from "../CollectReservation";
import DataTable from "../../../Components/DataTable";

interface Reservation {
  ReserveID: number;
  ReserveDate: string;
  CustomerID: number;
  BranchID: number;
  ReserveUserID: number;
  TripTypeID: number;
  TripNo: string;
  TripDate: string;
  CountryFromID: number;
  CountryToID: number;
  PersonsCount: number;
  Total: number;
  Payed: number;
  Notes: string;
  SitsNumbers: number[];
}

type DataIndex = keyof Reservation;

const AllBookings: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);

  const {
    data: reservationsData,
    isError: isReservationsError,
    isLoading: isReservationsLoading,
  } = useGetReservationsQuery(undefined);

  const { data: customersData, isLoading: isCustomersLoading } =
    useGetCustomersQuery(undefined);

  const { data: countriesData, isLoading: isCountriesLoading } =
    useGetCountriesQuery(undefined);

  const { data: tripTypesData, isLoading: isTripTypesLoading } =
    useGetTripTypesQuery(undefined);
  const { data: servicesData, isLoading: isServicesLoading } =
    useGetServicesQuery(undefined);

  const reservations: Reservation[] = reservationsData?.ResponseObject ?? [];
  const customers = customersData?.ResponseObject ?? [];
  const countries = countriesData?.ResponseObject ?? [];
  const tripTypes = tripTypesData?.ResponseObject ?? [];
  const services = servicesData?.ResponseObject || [];

  const [deleteReservation, { isLoading: delIsLoading }] =
    useDeleteReservationMutation();
  const handleDeleteReservation = (id: number) => {
    Modal.confirm({
      title: "هل أنت متأكد أنك تريد حذف هذا ",
      icon: <ExclamationCircleFilled />,
      content: "بالضغط على موافق سيتم حذف  بشكل دائم.",
      async onOk() {
        try {
          await deleteReservation(id);
          message.success("تم حذف  بنجاح");
        } catch (error) {
          console.error(error);
          message.error("فشل في حذف .");
        }
      },
    });
  };

  const getCustomerName = (id: any) => {
    console.log("customers", customers);

    return (
      customers.find((customer: any) => customer.CustomerID === id)
        ?.CustomerName || "Unknown"
    );
  };

  const getCountryName = (id: any) => {
    return (
      countries.find((country: any) => country.CountryID === id)?.CountryName ||
      "Unknown"
    );
  };

  const getTripTypeName = (id: any) => {
    return (
      tripTypes.find((type: any) => type.TripTypeID === id)?.TripTypeName ||
      "Unknown"
    );
  };

  const handleSearch = (
    selectedKeys: string[],
    confirm: () => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<Reservation> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters!)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      const targetValue = record[dataIndex];
      if (dataIndex === "CustomerID") {
        return getCustomerName(targetValue)
          .toLowerCase()
          .includes((value as string).toLowerCase());
      }
      if (dataIndex === "TripTypeID") {
        return getTripTypeName(targetValue)
          .toLowerCase()
          .includes((value as string).toLowerCase());
      }
      return targetValue
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text?.toString() || ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "رقم الحجز",
      dataIndex: "ReserveID",
      key: "ReserveID",
      sorter: (a: Reservation, b: Reservation) => a.ReserveID - b.ReserveID,
      ...getColumnSearchProps("ReserveID"),
    },
    {
      title: "تاريخ الحجز",
      dataIndex: "ReserveDate",
      key: "ReserveDate",
      render: (date: string) => dayjs(date).format("YYYY-MM-DD"),
      width: 120,
    },
    {
      title: "اسم العميل",
      dataIndex: "CustomerID",
      key: "CustomerID",

      sorter: (a: Reservation, b: Reservation) => {
        const nameA = getCustomerName(a.CustomerID).toLowerCase();
        const nameB = getCustomerName(b.CustomerID).toLowerCase();
        return nameA.localeCompare(nameB);
      },
      ...getColumnSearchProps("CustomerID"),
      render: (id: number) => getCustomerName(id),
    },

    {
      title: "الخدمة",
      dataIndex: "ServiceID",
      key: "ServiceID",
      render: (id: number) =>
        services.find((service: any) => service.ServiceID === id)
          ?.ServiceName || "Unknown",
    },
    {
      title: "نوع الخدمة",
      dataIndex: "ServiceID",
      key: "ServiceID",
      render: (id: number) =>
        services.find((service: any) => service.ServiceID === id)
          ?.ServiceTypeName || "Unknown",
    },
    {
      title: "تاريخ الخدمة",
      dataIndex: "TripDate",
      key: "TripDate",
      render: (date: string) => dayjs(date).format("YYYY-MM-DD"),
      width: 120,
    },
    {
      title: "عدد الأشخاص",
      dataIndex: "PersonsCount",
      key: "PersonsCount",
      searchable: true,
    },
    {
      title: "الإجمالي",
      dataIndex: "Total",
      key: "Total",
      searchable: true,
    },
    {
      title: "المدفوع",
      dataIndex: "TotalPayed",
      key: "TotalPayed",
      render: (_: any, record: any) => record.TotalPayed,
    },

    {
      title: "المتبقي",
      dataIndex: "none",
      key: "none",
      render: (_: any, record: any) => {
        return (
          <span className="text-red-500">
            {record.Total - record.TotalPayed}
          </span>
        );
      },
    },
    {
      title: "طريقة الدفع",
      dataIndex: "PayType",
      key: "PayType",
      render: (PayType: number) => {
        return (
          paymentMethods.find((method: any) => method.value === PayType)
            ?.label || "Unknown"
        );
      },
      // width: 120,
    },
    {
      title: "العملة",
      dataIndex: "CurrName",
      key: "CurrName",
      searchable: true,
    },
    {
      title: "ملاحظات",
      dataIndex: "Notes",
      key: "Notes",
    },

    {
      title: "الإجراءات",
      key: "actions",
      render: (_: any, record: any) => (
        <div className="flex gap-2">
          <Button
            type="text"
            icon={<DeleteFilled />}
            danger
            onClick={() => handleDeleteReservation(record.ReserveID)}
          />
          <Button
            type="primary"
            onClick={() => {
              dispatch(saveReservationData(record));
              navigate("/Dashboard/CollectReservation");
            }}
          >
            تحصيل
          </Button>
        </div>
      ),
    },
  ];

  return (
    <section className="p-4 section_padding mt-0 pt-0 relative">
      <Heading heading="الحجوزات" />

      {isReservationsError && <ServerError />}

      <div className="mx-auto">
        <DataTable
          columns={columns}
          data={reservations}
          loading={
            isReservationsLoading ||
            isCustomersLoading ||
            isCountriesLoading ||
            isTripTypesLoading
          }
        />
      </div>
    </section>
  );
};

export default AllBookings;
