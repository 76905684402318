import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "./styles.css";
import { Autoplay, EffectFade, Pagination } from "swiper/modules";
import { useGetBannersQuery } from "../../redux/Service/api";

// Static fallback image
import FallbackImage from "../../assets/Slider1.png";

function Slider() {
  const {
    data: bannersData,
    isError: isBannersError,
    isLoading: isBannersLoading,
  } = useGetBannersQuery(undefined);

  const banners = bannersData?.data ?? [];

  // Check if there are banners or use fallback
  const slides =
    banners.length > 0
      ? banners.map((banner: any, index: number) => (
          <SwiperSlide key={index}>
            <img
              src={banner.photo}
              alt={banner.altText || `Banner ${index + 1}`}
              className="w-full min-h-[300px] h-[300px] object-contain"
            />
          </SwiperSlide>
        ))
      : [
          <SwiperSlide key="fallback">
            <img
              src={FallbackImage}
              alt="Fallback Banner"
              className="w-full min-h-[300px] h-[300px] object-contain"
            />
          </SwiperSlide>,
        ];

  // if (isBannersLoading) {
  //   return (
  //     <div className="flex items-center justify-center h-64">
  //       <p className="text-gray-500">Loading banners...</p>
  //     </div>
  //   );
  // }

  return (
    <div className="carousel">
      <Swiper
        spaceBetween={30}
        effect={"fade"}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, EffectFade, Pagination]}
        className="mySwiper w-full h-full"
      >
        {slides}
      </Swiper>
    </div>
  );
}

export default Slider;
