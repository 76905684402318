import React, { useState } from "react";
import { Table, Button, Modal, message } from "antd";
import {
  DeleteFilled,
  ExclamationCircleFilled,
  EditFilled,
} from "@ant-design/icons";
import {
  useGetCarsQuery,
  useGetVendorRequestsQuery,
  useDeleteVendorRequestMutation,
} from "../../../redux/Service/api";
import Heading from "../../../Components/Heading/Heading";
import ServerError from "../../../Components/utils/ServerError";
import dayjs from "dayjs";
import AddVendorRequest from "./AddVendorRequest";
import EditVendorRequest from "./EditVendorRequest";
import DataTable from "../../../Components/DataTable";

interface VendorRequest {
  VendorName: string;
  CarName: string;
  SellerName: string;
  CountryFromName: string;
  CountryToName: string;
  RequestNo: number;
  RequestDate: string;
  Price: number;
}

const SupplierRequests: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [entityForEdit, setEntityForEdit] = useState<any>(null);

  const { data: carsData, isLoading: isCarsLoading } =
    useGetCarsQuery(undefined);

  const {
    data: VendorsRequestsData,
    isLoading: isVendorRequestsLoading,
    isError,
  } = useGetVendorRequestsQuery(undefined);

  const [deleteVendorRequest, { isLoading: delIsLoading }] =
    useDeleteVendorRequestMutation();
  const handleDeleteVendorRequest = (id: number) => {
    Modal.confirm({
      title: "هل أنت متأكد أنك تريد حذف هذا ",
      icon: <ExclamationCircleFilled />,
      content: "بالضغط على موافق سيتم حذف  بشكل دائم.",
      async onOk() {
        try {
          await deleteVendorRequest(id);
          message.success("تم حذف  بنجاح");
        } catch (error) {
          console.error(error);
          message.error("فشل في حذف .");
        }
      },
    });
  };

  const cars = carsData?.ResponseObject ?? [];

  const vendorRequests: VendorRequest[] =
    VendorsRequestsData?.ResponseObject ?? [];

  const columns = [
    {
      title: "اسم المورد",
      dataIndex: "VendorName",
      key: "VendorName",
    },
    {
      title: "اسم السيارة",
      dataIndex: "CarId",
      key: "CarId",
      render: (value: any) =>
        cars.find((car: any) => car.CarID === value)?.Marka +
          " - " +
          cars.find((car: any) => car.CarID === value)?.Modal || "Unknown",
    },
    {
      title: "اسم مندوب التحصيل",
      dataIndex: "SellerName",
      key: "SellerName",
      searchable: true,
    },
    {
      title: "الدولة (من)",
      dataIndex: "CountryFromName",
      key: "CountryFromName",
      searchable: true,
    },
    {
      title: "الدولة (إلى)",
      dataIndex: "CountryToName",
      key: "CountryToName",
      searchable: true,
    },
    {
      title: "رقم الطلب",
      dataIndex: "RequestNo",
      key: "RequestNo",
      searchable: true,
    },
    {
      title: "تاريخ الطلب",
      dataIndex: "RequestDate",
      key: "RequestDate",
      render: (date: string) => dayjs(date).format("YYYY-MM-DD"),
    },
    {
      title: "السعر",
      dataIndex: "Price",
      key: "Price",
      searchable: true,
    },
    {
      title: "الإجراءات",
      key: "actions",
      render: (_: any, record: any) => (
        <div className="flex gap-2">
          {/* <Button
            type="text"
            icon={<EditFilled />}
            onClick={() => {
              setEntityForEdit(record);
              setOpenEditModal(true);
            }}
          /> */}
          <Button
            type="text"
            icon={<DeleteFilled />}
            danger
            onClick={() => handleDeleteVendorRequest(record.RequestNo)}
          />
        </div>
      ),
    },
  ];

  return (
    <section className="p-4 section_padding mt-0 pt-0 relative">
      <Heading heading="طلبات الموردين" />

      {isError ? <ServerError /> : null}

      <div className="text-center my-3">
        <Button
          className="w-[150px]"
          type="primary"
          onClick={() => setOpenModal(true)}
        >
          إضافة طلب
        </Button>
      </div>

      <div className="mx-auto">
        <DataTable
          columns={columns}
          data={vendorRequests}
          loading={isVendorRequestsLoading}
        />
      </div>

      <AddVendorRequest openModal={openModal} setOpenModal={setOpenModal} />
      <EditVendorRequest
        openModal={openEditModal}
        setOpenModal={setOpenEditModal}
        entity={entityForEdit}
      />
    </section>
  );
};

export default SupplierRequests;
