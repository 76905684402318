import Features1 from "../../assets/Features1.gif";
import Features2 from "../../assets/Features2.gif";
import Features3 from "../../assets/Features3.gif";

function OurFeatures() {
  return (
    <div className="container font-[sans-serif] mt-28">
      <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-12">
        <div className="flex flex-col justify-center items-center">
          <img className="w-[80px]" src={Features1} alt="" />
          <h3 className="text-PumpkinOrange text-xl font-semibold mb-3">
            أسعار تنافسية لا تقارن
          </h3>
          <p className="text-gray-600 text-sm">
            نقدم لك أفضل الأسعار التي تلبي احتياجاتك.
          </p>
        </div>
        <div className="flex flex-col justify-center items-center">
          <img className="w-[80px]" src={Features2} alt="" />
          <h3 className="text-PumpkinOrange text-xl font-semibold mb-3">
            راحة ومتعة السفر
          </h3>
          <p className="text-gray-600 text-sm">
            استمتع بتجربة سفر مريحة وممتعة مع خدماتنا المتنوعة.
          </p>
        </div>
        <div className="flex flex-col justify-center items-center">
          <img className="w-[80px]" src={Features3} alt="" />
          <h3 className="text-PumpkinOrange text-xl font-semibold mb-3">
            خدمة 5 نجوم
          </h3>
          <p className="text-gray-600 text-sm">
            نقدم لك خدمة عملاء على مدار الساعة لتلبية احتياجاتك.
          </p>
        </div>
      </div>
    </div>
  );
}

export default OurFeatures;
