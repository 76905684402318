import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { Outlet } from "react-router-dom";
import ScrollToTop from "../../utils/ScrollToTop";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { restoreAuth } from "../../redux/Slices/authSlice";

function RootLayout() {
  const dispatch = useDispatch();

  useEffect(() => {
    const userDataString = localStorage.getItem("zahranUserData");

    if (userDataString) {
      const userData = JSON.parse(userDataString);
      dispatch(restoreAuth(userData));
    }
  }, [dispatch]);

  return (
    <>
      <ScrollToTop />
      <Header />
      {/* <Slider /> */}
      <Outlet />
      {/* <OurFeatures /> */}
      {/* <OurOffers /> */}
      <Footer />
    </>
  );
}

export default RootLayout;
