import { Button, Modal, Form, Input, Select, TimePicker, message } from "antd";
import {
  useGetCarsQuery,
  useGetDriversQuery,
  useGetBranchesQuery,
  useGetCountriesQuery,
  useAddItineraryMutation,
} from "../../../redux/Service/api";

const Add = ({ openModal, setOpenModal }: any) => {
  const [form] = Form.useForm();

  const [addItinerary, { isLoading: isAdding, isError: isAddingError }] =
    useAddItineraryMutation();

  const { data: driversData, isLoading: isDriversLoading } =
    useGetDriversQuery(undefined);
  const { data: branchesData, isLoading: isBranchesLoading } =
    useGetBranchesQuery(undefined);
  const { data: countriesData, isLoading: isCountriesLoading } =
    useGetCountriesQuery(undefined);

  const { data: carsData, isLoading: isCarsLoading } =
    useGetCarsQuery(undefined);

  const drivers = driversData?.ResponseObject ?? [];
  const branches = branchesData?.ResponseObject ?? [];
  const countries = countriesData?.ResponseObject ?? [];
  const cars = carsData?.ResponseObject ?? [];

  const handleAddItinerary = async () => {
    try {
      const values = await form.validateFields();
      const itineraryPayload = {
        CarId: values.CarId,
        DriverID: values.DriverID,
        BranchID: values.BranchID,
        CountryFromID: values.CountryFromID,
        CountryToID: values.CountryToID,
        StartTime: values.StartTime.format("HH:mm:ss"),
        ArriveTime: values.ArriveTime.format("HH:mm:ss"),
        SitValue: values.SitValue,
      };
      const response = await addItinerary(itineraryPayload);
      if (response?.data) {
        message.success("تمت إضافة خط السير بنجاح");
        form.resetFields();
        setOpenModal(false);
      }
    } catch (error) {
      console.error("خطأ أثناء إضافة خط السير:", error);
      message.error("فشل في إضافة خط السير. يرجى المحاولة مرة أخرى.");
    }
  };

  return (
    <Modal
      title="إضافة خط سير"
      centered
      open={openModal}
      onCancel={() => {
        setOpenModal(false);
        form.resetFields();
      }}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        name="add_itinerary_form"
        onFinish={handleAddItinerary}
      >
        <Form.Item
          label="السيارة"
          name="CarId"
          rules={[{ required: true, message: "يرجى اختيار السيارة" }]}
        >
          <Select size="large" placeholder="اختر السيارة">
            {cars.map((car: any) => (
              <Select.Option key={car.CarID} value={car.CarID}>
                {car.Marka} / {car.Modal} / {car.CarNumber}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="السائق"
          name="DriverID"
          rules={[{ required: true, message: "يرجى اختيار السائق" }]}
        >
          <Select size="large" placeholder="اختر السائق">
            {drivers.map((driver: any) => (
              <Select.Option key={driver.DriverID} value={driver.DriverID}>
                {driver.DriverName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="الفرع"
          name="BranchID"
          rules={[{ required: true, message: "يرجى اختيار الفرع" }]}
        >
          <Select size="large" placeholder="اختر الفرع">
            {branches.map((branch: any) => (
              <Select.Option key={branch.BranchID} value={branch.BranchID}>
                {branch.BranchName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="الدولة (من)"
          name="CountryFromID"
          rules={[{ required: true, message: "يرجى اختيار الدولة (من)" }]}
        >
          <Select size="large" placeholder="اختر الدولة (من)">
            {countries.map((country: any) => (
              <Select.Option key={country.CountryID} value={country.CountryID}>
                {country.CountryName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="الدولة (إلى)"
          name="CountryToID"
          rules={[{ required: true, message: "يرجى اختيار الدولة (إلى)" }]}
        >
          <Select size="large" placeholder="اختر الدولة (إلى)">
            {countries.map((country: any) => (
              <Select.Option key={country.CountryID} value={country.CountryID}>
                {country.CountryName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="وقت البداية"
          name="StartTime"
          rules={[{ required: true, message: "يرجى اختيار وقت البداية" }]}
        >
          <TimePicker
            size="large"
            className="w-full"
            placeholder="اختر وقت البداية"
          />
        </Form.Item>

        <Form.Item
          label="وقت الوصول"
          name="ArriveTime"
          rules={[{ required: true, message: "يرجى اختيار وقت الوصول" }]}
        >
          <TimePicker
            size="large"
            className="w-full"
            placeholder="اختر وقت الوصول"
          />
        </Form.Item>

        <Form.Item
          label="سعر الكرسي"
          name="SitValue"
          rules={[{ required: true, message: "يرجى إدخال سعر الكرسي" }]}
        >
          <Input size="large" type="number" placeholder="أدخل سعر الكرسي" />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={isAdding}
            className="w-full"
          >
            إضافة
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Add;
