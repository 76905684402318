import React from "react";
import { useNavigate } from "react-router-dom";
import { primaryBaseUrl, useGetTripTypesQuery } from "../../redux/Service/api";
import fallBackImage from "../../assets/Bookings1.png";

// Define TripType interface if needed
interface TripType {
  ServiceTypeID: number;
  ServiceTypeName: string;
  ServiceTypeImageName: string; // Add this if your API returns image URLs for trip types
}

const ServicesSection = () => {
  const navigate = useNavigate();

  // Fetch trip types data
  const {
    data: serviceTypesData,
    isError: isServiceTypesError,
    isLoading: isServiceTypesLoading,
  } = useGetTripTypesQuery(undefined);

  const serviceTypes: TripType[] = serviceTypesData?.ResponseObject ?? [];

  if (isServiceTypesLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <p className="text-gray-500">Loading services...</p>
      </div>
    );
  }

  if (isServiceTypesError) {
    return (
      <div className="flex items-center justify-center h-64">
        <p className="text-red-500">
          Failed to load services. Please try again.
        </p>
      </div>
    );
  }

  if (serviceTypes.length === 0) {
    return (
      <div className="flex items-center justify-center h-64">
        <p className="text-gray-500">No services available at the moment.</p>
      </div>
    );
  }

  return (
    <div className="container mx-auto py-10">
      {/* Section Title */}
      <h2 className="text-center text-2xl md:text-3xl lg:text-4xl font-bold text-gray-800 mb-8">
        أنواع الخدمات
      </h2>

      {/* Services Grid */}
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-5 sm:gap-7 lg:gap-8 justify-center">
        {serviceTypes?.map((serviceType) => (
          <div
            key={serviceType.ServiceTypeID}
            className="cursor-pointer grid gap-3 p-3 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow"
            onClick={() =>
              navigate(`/servicesType/${serviceType.ServiceTypeID}`)
            }
          >
            <img
              src={
                serviceType.ServiceTypeImageName
                  ? primaryBaseUrl + serviceType.ServiceTypeImageName
                  : fallBackImage
              }
              className="w-full object-cover h-36 sm:h-44 lg:h-48 rounded-md"
              alt={serviceType.ServiceTypeName}
            />
            <div className="px-2 py-2.5 rounded-md text-blue-900 text-sm tracking-wider font-bold">
              {serviceType.ServiceTypeName}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServicesSection;
