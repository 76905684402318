import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="bg-[#0E104F] text-white py-8">
      <div className="container grid sm:grid-cols-2 lg:grid-cols-3 gap-10 justify-center items-center">
        <ul className="flex flex-col gap-5">
          <li className="-mt-4 lg:-mt-12">
            <Link
              to="/"
              className="transition-all hover:text-PumpkinOrange font-semibold text-[2rem]"
            >
              زهران
            </Link>
          </li>
          <li>
            <Link
              to="/contact"
              className="transition-all hover:text-PumpkinOrange"
            >
              تواصل معنا
            </Link>
          </li>
          <li>
            <Link
              to="/about"
              className="transition-all hover:text-PumpkinOrange"
            >
              عن الشركه
            </Link>
          </li>
        </ul>
        <ul className="flex flex-col gap-5">
          <li>
            <Link
              to="tel:+201222214924"
              className="transition-all hover:text-PumpkinOrange"
            >
              {"01222214924 2+"}
            </Link>
          </li>
          <li>
            <Link
              to="mailto:damietta@zahrantravel.com"
              className="transition-all hover:text-PumpkinOrange"
            >
              damietta@zahrantravel.com
            </Link>
          </li>
        </ul>
        <ul className="flex flex-col gap-5">
          <li className="text-xs font-semibold">
            <a
              href="https://maps.app.goo.gl/DCrhCw73Qy8gqJ3C6"
              className="transition-all hover:text-PumpkinOrange"
            >
              شارع وسط البلد امام الحي المميز بجوار الموقف الشرقي، مدينة دمياط
              الجديدة
            </a>
          </li>
          <li>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3403.900271194496!2d31.7001682245521!3d31.44441237424894!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14f9e308132fe21d%3A0xe49dd9bfe0e2977d!2z2LLZh9ix2KfZhiDYqtix2KfZgdmE!5e0!3m2!1sar!2seg!4v1727445598101!5m2!1sar!2seg"
              width="100%"
              height="150"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;
