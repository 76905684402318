import { Button, Form, Input, Modal, message } from "antd";
import { useAddBranchMutation } from "../../../redux/Service/api";

const AddBranch = ({ openModal, setOpenModal }: any) => {
  const [form] = Form.useForm();

  const [addBranch, { isLoading }] = useAddBranchMutation();
  const handleAddBranch = async () => {
    try {
      const values = await form.validateFields();
      await addBranch(values);
      message.success("تمت إضافة الفرع بنجاح");
      form.resetFields();
      setOpenModal(false);
    } catch (error) {
      console.error("خطأ أثناء إضافة الفرع:", error);
      message.error("فشل في إضافة الفرع. يرجى المحاولة مرة أخرى.");
    }
  };

  return (
    <Modal
      title="إضافة فرع"
      centered
      open={openModal}
      onCancel={() => setOpenModal(false)}
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={handleAddBranch}>
        <Form.Item
          label="اسم الفرع"
          name="BranchName"
          rules={[{ required: true, message: "يرجى إدخال اسم الفرع" }]}
        >
          <Input size="large" placeholder="أدخل اسم الفرع" />
        </Form.Item>
        <Button type="primary" htmlType="submit" loading={isLoading} block>
          إضافة
        </Button>
      </Form>
    </Modal>
  );
};

export default AddBranch;
