import { Form, Button, Table, message, Select } from "antd";
import { useLazyGetCustomerReservationsQuery } from "../../../redux/Service/api";
const { Option } = Select;
const CustomerReservationsDetails = ({ customers }: any) => {
  const [form] = Form.useForm();
  const [getData, { data: customerReservationsData, isFetching }] =
    useLazyGetCustomerReservationsQuery();
  const customerReservations = customerReservationsData?.ResponseObject || [];

  const handleSearch = async () => {
    try {
      const values = await form.validateFields();
      const customerID = values.CustomerID;
      if (!customerID) {
        message.error("يرجى إدخال رقم العميل");
        return;
      }

      getData(customerID); // Fetch reservations by CustomerID
    } catch (error) {
      console.error("Error during search:", error);
    }
  };

  // Calculate the total difference (Total - TotalPayed)
  const totalDifference = customerReservations.reduce(
    (acc: any, reservation: any) =>
      acc + (reservation.Total - reservation.TotalPayed),
    0
  );

  const columns = [
    {
      title: "رقم الحجز",
      dataIndex: "ReserveID",
      key: "ReserveID",
    },
    {
      title: "تاريخ الحجز",
      dataIndex: "ReserveDate",
      key: "ReserveDate",
    },
    {
      title: "رقم الفرع",
      dataIndex: "BranchID",
      key: "BranchID",
    },
    {
      title: "عدد الأفراد",
      dataIndex: "PersonsCount",
      key: "PersonsCount",
    },
    {
      title: "المبلغ الإجمالي",
      dataIndex: "Total",
      key: "Total",
    },
    {
      title: "المبلغ المدفوع",
      dataIndex: "TotalPayed",
      key: "TotalPayed",
    },
    {
      title: "الفرق (المتبقي)",
      key: "Difference",
      render: (_: any, record: any) => record.Total - record.TotalPayed,
    },
    {
      title: "ملاحظات",
      dataIndex: "Notes",
      key: "Notes",
    },
  ];

  return (
    <div className="border-t  mt-4 py-4">
      <div className="text-2xl font-semibold mb-4 text-center">
        تفاصيل الحجوزات الخاصة بالعميل
      </div>
      <Form form={form} layout="inline" onFinish={handleSearch}>
        <Form.Item
          label="العميل"
          name="CustomerID"
          rules={[{ required: true, message: "يرجى اختيار العميل" }]}
        >
          <Select
            size="large"
            placeholder="اختر العميل"
            showSearch
            className="min-w-[200px]"
          >
            {customers?.map((customer: any) => (
              <Option key={customer.CustomerID} value={customer.CustomerID}>
                {customer.CustomerName}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isFetching}>
            بحث
          </Button>
        </Form.Item>
      </Form>

      <Table
        dataSource={customerReservations}
        columns={columns}
        rowKey="ReserveID"
        loading={isFetching}
        className="mt-4"
        summary={() => (
          <Table.Summary.Row>
            <Table.Summary.Cell
              index={0}
              colSpan={7}
              className="flex justify-between items-center gap-2 w-full"
            >
              <b>إجمالي الفرق (المتبقي):</b>{" "}
              <b>{customerReservations?.length > 0 ? totalDifference : ""}</b>
            </Table.Summary.Cell>
          </Table.Summary.Row>
        )}
      />
    </div>
  );
};

export default CustomerReservationsDetails;
