import React, { useState } from "react";
import {
  DeleteFilled,
  EditFilled,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import { Button, Modal, Table, message } from "antd";
import {
  useGetCurrenciesQuery,
  useDeleteCurrencyMutation,
} from "../../../redux/Service/api";
import Heading from "../../../Components/Heading/Heading";
import ServerError from "../../../Components/utils/ServerError";
import AddCurrency from "./AddCurrency";
import EditCurrency from "./EditCurrency";
import DataTable from "../../../Components/DataTable";

const { confirm } = Modal;

interface Currency {
  CurrID?: any;
  CurrName: string;
  CurrPart: string;
  CurrRate: number;
  IsDefualte: boolean;
}

const CurrencyCodes: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [entityForEdit, setEntityForEdit] = useState<Currency | null>(null);

  const [deleteCurrency, { isLoading: delIsLoading }] =
    useDeleteCurrencyMutation();
  const {
    data: currenciesData,
    isError,
    isLoading: isCurrenciesLoading,
  } = useGetCurrenciesQuery(undefined);

  const currencies: Currency[] = currenciesData?.ResponseObject ?? [];

  const handleDeleteCurrency = (CurrID: string) => {
    confirm({
      title: "هل أنت متأكد أنك تريد حذف هذه العملة؟",
      icon: <ExclamationCircleFilled />,
      content: "بالضغط على موافق سيتم حذف العملة بشكل دائم.",
      async onOk() {
        try {
          await deleteCurrency(CurrID);
          message.success("تم حذف العملة بنجاح");
        } catch (error) {
          console.error(error);
          message.error("فشل في حذف العملة.");
        }
      },
    });
  };

  const columns = [
    {
      title: "اسم العملة",
      dataIndex: "CurrName",
      key: "CurrName",
      searchable: true,
    },
    {
      title: "الوحدة الفرعية",
      dataIndex: "CurrPart",
      key: "CurrPart",
      searchable: true,
    },
    {
      title: "سعر الصرف",
      dataIndex: "CurrRate",
      key: "CurrRate",
      searchable: true,
    },
    {
      title: "افتراضية",
      dataIndex: "IsDefualte",
      key: "IsDefualte",
      render: (isDefault: boolean) => (isDefault ? "نعم" : "لا"),
    },
    {
      title: "الإجراءات",
      key: "actions",
      render: (_: any, currency: Currency) => (
        <div className="flex gap-2">
          <Button
            type="text"
            icon={<EditFilled />}
            onClick={() => {
              setEntityForEdit(currency);
              setOpenEditModal(true);
            }}
          />
          <Button
            type="text"
            icon={<DeleteFilled />}
            danger
            onClick={() => handleDeleteCurrency(currency.CurrID)}
          />
        </div>
      ),
    },
  ];

  return (
    <section className="p-4 section_padding mt-0 pt-0 relative">
      <Heading heading="العملات" />

      {isError && <ServerError />}

      <div className="text-center my-3">
        <Button
          className="w-[150px]"
          type="primary"
          onClick={() => setOpenModal(true)}
        >
          إضافة عملة
        </Button>
      </div>

      <div className="mx-auto">
        <DataTable
          columns={columns}
          data={currencies}
          loading={isCurrenciesLoading || delIsLoading}
        />
      </div>

      <AddCurrency openModal={openModal} setOpenModal={setOpenModal} />
      <EditCurrency
        openModal={openEditModal}
        setOpenModal={setOpenEditModal}
        entity={entityForEdit}
      />
    </section>
  );
};

export default CurrencyCodes;
