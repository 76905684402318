import React from "react";
import { Table } from "antd";
import {
  primaryBaseUrl,
  useGetAvailableCarsQuery,
} from "../../../redux/Service/api";
import Heading from "../../../Components/Heading/Heading";
import ServerError from "../../../Components/utils/ServerError";
import dayjs from "dayjs";
import DataTable from "../../../Components/DataTable";

interface Car {
  CarID: number;
  CarName: string;
  CarNumber: string;
  Modal: string;
  Marka: string;
  Price: number;
  CarImageName: string;
}

const AvailableCars: React.FC = () => {
  const {
    data: carsData,
    isError: isCarsError,
    isLoading: isCarsLoading,
  } = useGetAvailableCarsQuery(undefined);

  const cars: Car[] =
    carsData?.ResponseObject.map((car: Car) => ({
      ...car,
      CarName: `${car.Marka} - ${car.Modal} - ${car.CarNumber}`,
    })) ?? [];

  const columns = [
    {
      title: "اسم السيارة",
      dataIndex: "CarName",
      key: "CarName",
      searchable: true,
    },
    {
      title: "الرقم",
      dataIndex: "CarNumber",
      key: "CarNumber",
      searchable: true,
    },
    {
      title: "الموديل",
      dataIndex: "Modal",
      key: "Modal",
      searchable: true,
    },
    {
      title: "الماركة",
      dataIndex: "Marka",
      key: "Marka",
      searchable: true,
    },
    {
      title: "السعر",
      dataIndex: "Price",
      key: "Price",
      render: (price: number) => `${price.toFixed(2)} جنيه`,
    },
    {
      title: "الصورة",
      dataIndex: "CarImageName",
      key: "CarImageName",
      render: (image: string) =>
        image ? (
          <img
            src={`${primaryBaseUrl}${image}`}
            alt="Car"
            style={{ width: 50, height: 50, objectFit: "cover" }}
          />
        ) : (
          "لا توجد صورة"
        ),
    },
  ];

  return (
    <section className="p-4 section_padding mt-0 pt-0 relative">
      <Heading heading="السيارات المتوفرة" />

      {isCarsError && <ServerError />}

      <div className="mx-auto">
        <DataTable columns={columns} data={cars} loading={isCarsLoading} />
      </div>
    </section>
  );
};

export default AvailableCars;
