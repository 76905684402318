import { UserOutlined } from "@ant-design/icons";
import { Button, Layout, Menu, MenuProps, theme } from "antd";
import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faUsersBetweenLines } from "@fortawesome/free-solid-svg-icons";
import { LevelKeysProps, MenuItem } from "./Type";
// image
import ProSoft from "../assets/ProSoft.jpg";

const { Header, Sider, Content } = Layout;

const menuSlider: MenuItem[] = [
  {
    key: "#",
    icon: <UserOutlined className="!text-xl !font-black" />,
    label: "الرئيسيه",
  },
  {
    key: "2",
    icon: <FontAwesomeIcon icon={faGear} className="!text-xl !font-black" />,
    label: "أداره الحجوزات",
    children: [
      { key: "BookAFlight", label: " حجز خدمة " },
      { key: "CollectReservation", label: "تحصيل حجز" },
      { key: "Customer", label: "العملاء" },
      { key: "AddItinerary", label: "خطوط السير" },
      { key: "AvailableCars", label: "السيارات المتاحه" },
      { key: "SupplierRequests", label: "طلبات الموردين" },
      { key: "AllBookings", label: "كل الحجوزات" },
    ],
  },
  {
    key: "3",
    icon: (
      <FontAwesomeIcon
        icon={faUsersBetweenLines}
        className="!text-xl !font-black"
      />
    ),
    label: "الإعدادت",
    children: [
      { key: "TypesOfTrips", label: " انواع الخدمات " },
      { key: "Services", label: "  الخدمات " },
      { key: "TypesOfCars", label: "انواع السيارات " },
      { key: "DriversCodes", label: "أكواد السائقين " },
      { key: "CityCodes", label: "أكواد المدن" },
      { key: "SystemUsers", label: "مستخدمى النظام" },
      { key: "Branches", label: "الفروع" },
      { key: "Currencies", label: "العملات" },
      { key: "SliderSettings", label: "السليدر" },
      { key: "AboutUsSettings", label: "عن الشركه " },
    ],
  },
];

const getLevelKeys = (items1: LevelKeysProps[]) => {
  const key: Record<string, number> = {};
  const func = (items2: LevelKeysProps[], level = 1) => {
    items2.forEach((item) => {
      if (item.key) {
        key[item.key] = level;
      }
      if (item.children) {
        func(item.children, level + 1);
      }
    });
  };
  func(items1);
  return key;
};

function Dashboard() {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState<any>(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  // بدايه اذا تم فاتح 2 منيو سوف يقفل الاول
  const [stateOpenKeys, setStateOpenKeys] = useState(["1", "1"]);
  const onOpenChange: MenuProps["onOpenChange"] = (openKeys) => {
    const currentOpenKey = openKeys.find(
      (key) => stateOpenKeys.indexOf(key) === -1
    );
    if (currentOpenKey !== undefined) {
      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);
      setStateOpenKeys(
        openKeys
          .filter((_, index) => index !== repeatIndex)
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey])
      );
    } else {
      setStateOpenKeys(openKeys);
    }
  };

  const levelKeys = getLevelKeys(menuSlider as LevelKeysProps[]);
  // نهايه اذا تم فاتح 2 منيو سوف يقفل الاول

  // تشغيل الرابط بنا علي اسم key
  const handleClick = (event: any) => {
    const clickTab = event.key;
    navigate(`/Dashboard/${clickTab}`);
  };
  // تشغيل الرابط بنا علي اسم key.
  return (
    <Layout className="slider-Dashboard !h-[100vh]">
      <Sider
        // يظهر زرار تلقائي له تحكم في قفل slide وفتحها
        // breakpoint="xxl"
        // collapsedWidth="0"
        collapsed={collapsed}
        collapsible
        onCollapse={(collapsed, type) => {
          setCollapsed(collapsed);
        }}
        width={200}
        style={{
          position: "fixed",
          height: "100vh",
          top: 0,
          bottom: 0,
          zIndex: 1000,
          // width: "35rem",
        }}
      >
        <div
          className="h-10 m-1 bg-white bg-opacity-80 rounded-lg cursor-pointer flex items-center justify-center"
          onClick={() => navigate("/")}
        >
          <img
            className="w-full h-full object-fill !rounded-md"
            src={ProSoft}
            width={100}
            alt="logo"
          />
        </div>
        <Menu
          className="relative"
          theme="dark"
          mode="inline"
          defaultSelectedKeys={["#"]}
          openKeys={stateOpenKeys}
          items={menuSlider}
          onClick={handleClick}
          onOpenChange={onOpenChange}
        />
        {/* <Button
          className="absolute left-[-40px] top-[15%] !bg-blue-700 !text-white
          hover:!text-[#cacaca] hover:!bg-blue-600 active:!text-blue-900 rounded-r-none"
          type="text"
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={() => {
            console.log("Button clicked");
            setCollapsed(!collapsed);
          }}
          style={{
            fontSize: "16px",
            width: 40,
            height: 40,
            zIndex: 1000,
          }}
        /> */}
      </Sider>
      <Layout className="!h-fit !min-h-[100vh]">
        {/* <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        /> */}
        <Content
          style={{
            margin: "24px 16px",
            transition: "all 0.2s ease-in-out",
            marginRight: `${collapsed ? "80px" : "200px"}`,
            // padding: 24,
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
}

export default Dashboard;
