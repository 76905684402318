import { createBrowserRouter } from "react-router-dom";
import RootLayout from "../Components/RootLayout/RootLayout";
import { ErrorPage } from "./ErrorPage";
import Home from "../Page/Home";
import Dashboard from "../Dashboard";
import AboutUs from "../Page/AboutUs";
import Bookings from "../Page/Bookings";
import Bus from "../Page/Bus";
import Trip from "../Page/Trip";
import HomeDashboard from "../Dashboard/HomeDashboard";
import BookAFlight from "../Dashboard/ManageReservations/BookAFlight";
import CollectReservation from "../Dashboard/ManageReservations/CollectReservation";
import AddItinerary from "../Dashboard/ManageReservations/AddItinerary";
import TypesOfTrips from "../Dashboard/Settings/TypesOfTrips";
import TypesOfCars from "../Dashboard/Settings/TypesOfCars";
import DriversCodes from "../Dashboard/Settings/DriversCodes";
import AllBookings from "../Dashboard/ManageReservations/AllBookings";
import AvailableCars from "../Dashboard/ManageReservations/AvailableCars";
// import Customer from "../Dashboard/Clients";
import SupplierRequests from "../Dashboard/ManageReservations/SupplierRequests";
import Customer from "../Dashboard/ManageReservations/Clients";
import Contact from "../Page/Contact";
import Login from "../Page/Login";
import Registration from "../Page/Registration";
import CityCodes from "../Dashboard/Settings/CityCodes";
import SystemUsers from "../Dashboard/Settings/SystemUsers";
import SliderSettings from "../Dashboard/Settings/SliderSettings";
import Branches from "../Dashboard/Settings/Branches";
import ServiceDetails from "../Page/ServiceDetails";
import Services from "../Dashboard/Settings/Servies";
import ServicesType from "../Page/ServicesType";
import CurrencyCodes from "../Dashboard/Settings/Currencies";
import AboutUsSettings from "../Dashboard/Settings/AboutUsSettings";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Home /> },
      { path: "AboutUs", element: <AboutUs /> },
      { path: "Contact", element: <Contact /> },
      { path: "Bookings", element: <Bookings /> },
      { path: "Bus", element: <Bus /> },
      { path: "Trip", element: <Trip /> },
      { path: "Login", element: <Login /> },
      { path: "Registration", element: <Registration /> },
      { path: "ServicesType/:serviceTypeId", element: <ServicesType /> },
      { path: "ServiceDetails/:serviceId", element: <ServiceDetails /> },
    ],
  },
  {
    path: "dashboard",
    element: <Dashboard />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <HomeDashboard /> },
      // {{أداره الحجوزات}}
      //  حجز رحله
      { path: "BookAFlight", element: <BookAFlight /> },
      // تحصيل حجز
      { path: "CollectReservation", element: <CollectReservation /> },
      //  العملاء
      { path: "Customer", element: <Customer /> },
      // اضافه خط سير
      { path: "AddItinerary", element: <AddItinerary /> },
      //   السيارات المتاحه
      { path: "AvailableCars", element: <AvailableCars /> },
      //   السيارات المتاحه
      { path: "SupplierRequests", element: <SupplierRequests /> },
      //  كل الحجوزات
      { path: "AllBookings", element: <AllBookings /> },
      // {{الاعدادات}}
      //  انواع الرحلات
      { path: "TypesOfTrips", element: <TypesOfTrips /> },
      //  الخدمات
      { path: "Services", element: <Services /> },
      //   انواع السيارات
      { path: "TypesOfCars", element: <TypesOfCars /> },
      //   اكواد السائقين
      { path: "DriversCodes", element: <DriversCodes /> },
      //   اكواد المدن
      { path: "CityCodes", element: <CityCodes /> },
      //    مستخدمى النظام
      { path: "SystemUsers", element: <SystemUsers /> },
      //    مستخدمى النظام
      { path: "Branches", element: <Branches /> },
      { path: "Currencies", element: <CurrencyCodes /> },
      //    السلايدر
      { path: "SliderSettings", element: <SliderSettings /> },
      { path: "AboutUsSettings", element: <AboutUsSettings /> },
    ],
  },
]);
