import React, { useState } from "react";
import { DeleteFilled } from "@ant-design/icons";
import { Button, Form, Input, Modal, Select, Table, message } from "antd";
import {
  useGetDriversQuery,
  useAddDriverMutation,
  useDeleteDriverMutation,
  useGetCarsQuery,
} from "../../../redux/Service/api";
import { ExclamationCircleFilled, EditFilled } from "@ant-design/icons";
import Heading from "../../../Components/Heading/Heading";
import ServerError from "../../../Components/utils/ServerError";
import AddDriver from "./AddDriver";
import EditDriver from "./EditDriver";
import DataTable from "../../../Components/DataTable";

const { confirm } = Modal;
const { Option } = Select;

interface Driver {
  DriverID: number;
  DriverName: string;
  Address: string;
  Phone: string;
  CarId: number;
}

const Drivers: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [entityForEdit, setEntityForEdit] = useState<any>(null);

  const [deleteDriver, { isLoading: delIsLoading }] = useDeleteDriverMutation();
  const {
    data: driversData,
    isError: isDriversError,
    isLoading: isDriversLoading,
  } = useGetDriversQuery(undefined);
  const {
    data: carsData,
    isError: isCarsError,
    isLoading: isCarsLoading,
  } = useGetCarsQuery(undefined);

  const drivers: Driver[] = driversData?.ResponseObject ?? [];
  const cars = carsData?.ResponseObject ?? [];

  const handleDeleteDriver = (id: number) => {
    confirm({
      title: "هل أنت متأكد أنك تريد حذف هذا السائق؟",
      icon: <ExclamationCircleFilled />,
      content: "بالضغط على موافق سيتم حذف السائق بشكل دائم.",
      async onOk() {
        try {
          await deleteDriver(id);
          message.success("تم حذف السائق بنجاح");
        } catch (error) {
          console.error(error);
          message.error("فشل في حذف السائق.");
        }
      },
    });
  };

  const columns = [
    {
      title: "اسم السائق",
      dataIndex: "DriverName",
      key: "DriverName",
      searchable: true,
    },
    {
      title: "العنوان",
      dataIndex: "Address",
      key: "Address",
      searchable: true,
    },
    {
      title: "رقم الهاتف",
      dataIndex: "Phone",
      key: "Phone",
      searchable: true,
    },
    {
      title: "رقم السيارة",
      dataIndex: "CarId",
      key: "CarId",
      render: (carId: number) => {
        const car = cars.find((car: any) => car.CarID === carId);
        return car?.CarNumber || "غير معروف";
      },
    },
    {
      title: "الإجراءات",
      key: "actions",
      render: (_: any, driver: Driver) => (
        <div className="flex gap-2">
          <Button
            type="text"
            icon={<EditFilled />}
            onClick={() => {
              setEntityForEdit(driver);
              setOpenEditModal(true);
            }}
          />
          <Button
            type="text"
            icon={<DeleteFilled />}
            danger
            onClick={() => handleDeleteDriver(driver.DriverID)}
          />
        </div>
      ),
    },
  ];

  return (
    <section className="p-4 section_padding mt-0 pt-0 relative">
      <Heading heading="السائقين" />

      {isDriversError && <ServerError />}

      <div className="text-center my-3">
        <Button
          className="w-[150px]"
          type="primary"
          onClick={() => setOpenModal(true)}
        >
          إضافة سائق
        </Button>
      </div>

      <div className="mx-auto">
        <DataTable
          columns={columns}
          data={drivers}
          loading={isDriversLoading || delIsLoading}
        />
      </div>

      <AddDriver openModal={openModal} setOpenModal={setOpenModal} />
      <EditDriver
        openModal={openEditModal}
        setOpenModal={setOpenEditModal}
        entity={entityForEdit}
      />
    </section>
  );
};

export default Drivers;
