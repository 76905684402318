import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useRegisterMutation } from "../../redux/Service/api";
import { Form, Input, Button, message } from "antd";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";

const Registration: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  // Destructure the mutation function and status properties
  const [registerUser, { isLoading, isError, error }] =
    useRegisterMutation<any>();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = async (values: any) => {
    const requestData = {
      UserName: values.UserName,
      UserPassword: values.UserPassword,
      Email: values.Email,
      UserRole: 1,
    };

    try {
      const userData = await registerUser(requestData).unwrap();
      if (userData) {
        message.success("يمكنك الان تسجيل الدخول");
        navigate("/Login");
      }
    } catch (err) {
      console.error("Registration failed:", err);
      message.error("Registration failed. Please try again.");
    }
  };

  return (
    <div className="font-[sans-serif] mt-[80px] md:my-[50px]">
      <div className="min-h-screen container flex flex-col items-center justify-center py-6 px-4">
        <div className="grid md:grid-cols-2 items-center gap-4 max-w-6xl w-full">
          <div className="border border-gray-300 rounded-lg p-6 max-w-md shadow-[0_2px_22px_-4px_rgba(93,96,127,0.2)] max-md:mx-auto">
            <Form
              form={form}
              layout="vertical"
              onFinish={handleSubmit}
              initialValues={{ UserName: "", UserPassword: "", Email: "" }}
              autoComplete="off"
            >
              <div className="mb-8">
                <h3 className="text-gray-800 text-3xl font-extrabold">
                  تسجيل حساب جديد
                </h3>
                <p className="text-gray-500 text-sm mt-4 leading-relaxed">
                  قم بإنشاء حساب جديد وانضم إلى مجتمعنا. رحلتك تبدأ هنا.
                </p>
              </div>

              <Form.Item
                label="اسم المستخدم"
                name="UserName"
                rules={[{ required: true, message: "يرجى إدخال اسم المستخدم" }]}
              >
                <Input
                  className="text-sm text-gray-800 border border-gray-300 px-4 py-3 rounded-lg outline-blue-600"
                  placeholder="أدخل اسم المستخدم"
                />
              </Form.Item>

              <Form.Item
                label="البريد الإلكتروني أو رقم الهاتف"
                name="Email"
                rules={[
                  {
                    required: true,
                    message: "يرجى إدخال البريد الإلكتروني أو رقم الهاتف",
                  },
                  {
                    validator: (_, value) => {
                      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                      const phoneRegex = /^(010|011|012|015)\d{8}$/;

                      if (
                        !value ||
                        emailRegex.test(value) ||
                        phoneRegex.test(value)
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "يرجى إدخال بريد إلكتروني صحيح أو رقم هاتف صحيح مكون من 11 رقماً"
                      );
                    },
                  },
                ]}
              >
                <Input
                  className="text-sm text-gray-800 border border-gray-300 px-4 py-3 rounded-lg outline-blue-600"
                  placeholder="أدخل البريد الإلكتروني أو رقم الهاتف"
                />
              </Form.Item>

              <Form.Item
                label="كلمة المرور"
                name="UserPassword"
                rules={[{ required: true, message: "يرجى إدخال كلمة المرور" }]}
              >
                <div className="relative flex items-center">
                  <Input
                    name="UserPassword"
                    type={showPassword ? "text" : "password"}
                    className="text-sm text-gray-800 border border-gray-300 px-4 py-3 rounded-lg outline-blue-600"
                    placeholder="أدخل كلمة المرور"
                  />
                  <div
                    className="absolute left-4 cursor-pointer"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                  </div>
                </div>
              </Form.Item>

              <Form.Item
                label="تأكيد كلمة المرور"
                name="ConfirmPassword"
                dependencies={["UserPassword"]}
                rules={[
                  { required: true, message: "يرجى تأكيد كلمة المرور" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("UserPassword") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("كلمة المرور غير متطابقة")
                      );
                    },
                  }),
                ]}
              >
                <div className="relative flex items-center">
                  <Input
                    name="ConfirmPassword"
                    type={showConfirmPassword ? "text" : "password"}
                    className="text-sm text-gray-800 border border-gray-300 px-4 py-3 rounded-lg outline-blue-600"
                    placeholder="أعد إدخال كلمة المرور"
                  />
                  <div
                    className="absolute left-4 cursor-pointer"
                    onClick={toggleConfirmPasswordVisibility}
                  >
                    {showConfirmPassword ? (
                      <EyeOutlined />
                    ) : (
                      <EyeInvisibleOutlined />
                    )}
                  </div>
                </div>
              </Form.Item>

              {isError && (
                <p className="text-sm text-red-600">
                  حدث خطأ أثناء التسجيل:{" "}
                  {error?.data?.message || error?.message}
                </p>
              )}

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  loading={isLoading}
                  className="text-sm tracking-wide rounded-lg"
                >
                  {isLoading ? "جاري التحميل..." : "تسجيل الحساب"}
                </Button>
              </Form.Item>

              <p className="text-sm !mt-8 text-center text-gray-800">
                لديك حساب بالفعل؟{" "}
                <Link
                  to="/Login"
                  className="text-blue-600 font-semibold hover:underline ml-1 whitespace-nowrap"
                >
                  سجل الدخول
                </Link>
              </p>
            </Form>
          </div>
          <div className="lg:h-[400px] md:h-[300px] max-md:mt-8">
            <img
              src="https://readymadeui.com/login-image.webp"
              className="w-full h-full max-md:w-4/5 mx-auto block object-cover"
              alt="تجربة تناول الطعام"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;
