import "./styles.css";
import Heading from "../../Components/Heading/Heading";
import ServicesSection from "../../Page/Home/ServicesSection";
import AllServices from "../../Page/Home/AllServices";

function HomeDashboard() {
  return (
    <>
      <Heading heading="ما نقدم" />
      <ServicesSection />

      <AllServices />
    </>
  );
}

export default HomeDashboard;
