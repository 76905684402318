import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Upload,
  message,
} from "antd";
import React, { useState, useEffect } from "react";
import {
  useEditServiceMutation,
  useGetTripTypesQuery,
} from "../../../redux/Service/api";
import { UploadOutlined } from "@ant-design/icons";

const { Option } = Select;

const EditService = ({ openModal, setOpenModal, entity, currencies }: any) => {
  const [modalKey, setModalKey] = useState(0);
  const [selectKey, setSelectKey] = useState(100);
  const [form] = Form.useForm();
  const [file, setFile] = useState<any>(null);

  const {
    data: tripTypesData,
    isError: isTripTypesError,
    isLoading: isTripTypesLoading,
  } = useGetTripTypesQuery(undefined);

  const tripTypes: any = tripTypesData?.ResponseObject ?? [];

  const [editService, { isLoading }] = useEditServiceMutation();

  useEffect(() => {
    console.log("entity", entity);

    if (entity) {
      form.setFieldsValue({
        ...entity,
        ServiceName: entity.ServiceName,
        ServiceTypeID: entity.ServiceTypeId,
        Price: entity.Price,
        TaxRate: entity.TaxRate,
        IsPerDay: entity.IsPerDay,
        IsDiffSalesPurch: entity.IsDiffSalesPurch,
        IsTotalInvoice: entity.IsTotalInvoice,
        DiscountPercent: entity.DiscountPercent,
        Description: entity.Description,
      });
    }
    setSelectKey((prev) => prev + 1);
  }, [entity, form]);

  const handleEditService = async (values: any) => {
    // console.log("intity", entity);
    // return;

    const formData = new FormData();
    if (file) formData.append("ServiceImageName", file);
    formData.append("ServiceId", String(entity.ServiceID));
    formData.append("ServiceName", values.ServiceName);
    formData.append("ServiceTypeId", String(values.ServiceTypeID));
    formData.append("Price", String(values.Price));
    formData.append("TaxRate", String(values.TaxRate));
    formData.append("IsPerDay", String(values.IsPerDay));
    formData.append("IsDiffSalesPurch", String(values.IsDiffSalesPurch));
    formData.append("IsTotalInvoice", String(values.IsTotalInvoice));
    formData.append("DiscountPercent", String(values.DiscountPercent));
    formData.append("Description", values.Description);
    formData.append("CurrId", values.CurrId);
    formData.append("CurrRate", values.CurrRate);

    try {
      await editService(formData);
      message.success("تم تعديل الخدمة بنجاح");
      form.resetFields();
      setModalKey((prev) => prev + 1);
      setOpenModal(false);
    } catch (error) {
      console.error("خطأ أثناء تعديل الخدمة:", error);
      message.error("فشل في تعديل الخدمة. يرجى المحاولة مرة أخرى.");
    }
  };

  const handleFileChange = (fileList: any) => {
    if (fileList.length > 0) {
      setFile(fileList[0].originFileObj);
    } else {
      setFile(null);
    }
  };

  return (
    <Modal
      title="تعديل خدمة"
      centered
      open={openModal}
      onCancel={() => setOpenModal(false)}
      footer={null}
      key={modalKey}
    >
      <Form form={form} layout="vertical" onFinish={handleEditService}>
        <Form.Item
          label="اسم الخدمة"
          name="ServiceName"
          rules={[{ required: true, message: "يرجى إدخال اسم الخدمة" }]}
        >
          <Input size="large" placeholder="أدخل اسم الخدمة" />
        </Form.Item>

        <Form.Item
          label="نوع الخدمة"
          name="ServiceTypeID"
          rules={[{ required: true, message: "يرجى اختيار نوع الخدمة" }]}
        >
          <Select
            size="large"
            placeholder="اختر نوع الخدمة"
            key={"serviceType" + selectKey}
          >
            {tripTypes?.map((tripType: any) => (
              <Option
                key={tripType.ServiceTypeID}
                value={tripType.ServiceTypeID}
              >
                {tripType.ServiceTypeName}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="العملة"
          name="CurrId"
          rules={[{ required: true, message: "يرجى اختيار العملة" }]}
        >
          <Select size="large" placeholder="اختر العملة">
            {currencies?.map((curr: any) => (
              <Option key={curr.CurrID} value={curr.CurrID}>
                {curr.CurrName}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="سعر العملة"
          name="CurrRate"
          rules={[{ required: true, message: "يرجى ادخال سعر العملة " }]}
        >
          <Input size="large" type="number" placeholder="أدخل سعر العملة" />
        </Form.Item>

        <Form.Item label=" برنامج الرحلة او وصف الخدمة" name="Description">
          <Input.TextArea
            size="large"
            placeholder="أدخل برنامج الرحلة او وصف الخدمة"
          />
        </Form.Item>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="السعر"
              name="Price"
              rules={[{ required: true, message: "يرجى إدخال السعر" }]}
            >
              <Input size="large" type="number" placeholder="أدخل السعر" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="نسبة الضريبة"
              name="TaxRate"
              rules={[{ required: true, message: "يرجى إدخال نسبة الضريبة" }]}
            >
              <Input
                size="large"
                type="number"
                placeholder="أدخل نسبة الضريبة"
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label="نسبة الخصم"
          name="DiscountPercent"
          rules={[{ required: true, message: "يرجى إدخال نسبة الخصم" }]}
        >
          <Input size="large" type="number" placeholder="أدخل نسبة الخصم" />
        </Form.Item>

        <Form.Item label="هل الحجز باليوم؟" name="IsPerDay">
          <Select
            size="large"
            placeholder="هل الحجز باليوم؟"
            key={"isPerDay" + selectKey}
          >
            <Option value={true}>نعم</Option>
            <Option value={false}>لا</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="هل يوجد فرق بين البيع والشراء؟"
          name="IsDiffSalesPurch"
        >
          <Select size="large" placeholder="هل يوجد فرق بين البيع والشراء؟">
            <Option value={true}>نعم</Option>
            <Option value={false}>لا</Option>
          </Select>
        </Form.Item>
        <Form.Item label="فوترة؟" name="IsTotalInvoice">
          <Select
            size="large"
            placeholder="فوترة؟"
            key={"isTotalInvoice" + selectKey}
          >
            <Option value={true}>نعم</Option>
            <Option value={false}>لا</Option>
          </Select>
        </Form.Item>

        <Form.Item label=" تعديل الصورة ">
          <Upload
            className="mb-2"
            customRequest={({ file, onSuccess }) => {
              onSuccess?.("ok");
            }}
            onChange={({ fileList }) => handleFileChange(fileList)}
            listType="picture"
            maxCount={1}
          >
            <Button icon={<UploadOutlined />}> تعديل الصورة </Button>
          </Upload>
        </Form.Item>

        <Button type="primary" htmlType="submit" loading={isLoading}>
          تعديل
        </Button>
      </Form>
    </Modal>
  );
};

export default EditService;
