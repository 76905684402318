import React, { useState } from "react";
import { Button, DatePicker, Form, Input, Select, message } from "antd";
import Heading from "../../../Components/Heading/Heading";
import TextArea from "antd/es/input/TextArea";
import {
  useGetCustomersQuery,
  useGetBranchesQuery,
  useGetTripTypesQuery,
  useGetCountriesQuery,
  useAddReservationMutation,
  useGetServicesQuery,
  useGetItinerariesQuery,
  useGetCurrenciesQuery,
} from "../../../redux/Service/api";

const { Option } = Select;

const BookAFlight: React.FC = () => {
  const [form] = Form.useForm();

  const [isTrip, setIsTrip] = useState(false);

  const { data: customersData, isLoading: isCustomersLoading } =
    useGetCustomersQuery(undefined);
  const { data: branchesData, isLoading: isBranchesLoading } =
    useGetBranchesQuery(undefined);
  const { data: tripTypesData, isLoading: isTripTypesLoading } =
    useGetTripTypesQuery(undefined);
  const { data: countriesData, isLoading: isCountriesLoading } =
    useGetCountriesQuery(undefined);
  const { data: servicesData, isLoading: isServicesLoading } =
    useGetServicesQuery(undefined);

  const { data: itinerariesData, isLoading: isItinerariesLoading } =
    useGetItinerariesQuery(undefined);

  const {
    data: currenciesData,
    isError,
    isLoading: isCurrenciesLoading,
  } = useGetCurrenciesQuery(undefined);

  const currencies: any[] = currenciesData?.ResponseObject ?? [];

  const [addReservation, { isLoading: isAdding }] = useAddReservationMutation();

  const customers = customersData?.ResponseObject || [];
  const branches = branchesData?.ResponseObject || [];
  // const tripTypes = tripTypesData?.ResponseObject || [];
  // const countries = countriesData?.ResponseObject || [];
  const services = servicesData?.ResponseObject || [];
  const itineraries = itinerariesData?.ResponseObject || [];

  const onFinish = async () => {
    try {
      const values = await form.validateFields();
      const requestBody = {
        ...values,
        ReserveDate: values.ReserveDate.format("YYYY-MM-DD"),
        TripDate: values.TripDate.format("YYYY-MM-DD"),
        SitsNumbers: [],
      };
      // console.log("requestBody", requestBody);

      // return

      const response = await addReservation(requestBody);
      if (response?.data) {
        message.success(" تمت انشاء الحجز بنجاح");
        form.resetFields();
      }
    } catch (error) {
      console.error("Error creating reservation:", error);
      message.error("Failed to create reservation. Please try again.");
    } finally {
      // setIsLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error("Failed:", errorInfo);
    message.error("Please complete all required fields.");
  };

  return (
    <div>
      <Heading heading="حجز خدمة" />
      <div className="container grid grid-cols-1 gap-0">
        <Form
          form={form}
          name="bookFlight"
          layout="vertical"
          initialValues={{ PersonsCount: 1, Total: 0, Payed: 0 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="form-grid"
        >
          <Form.Item
            label="تاريخ الحجز"
            name="ReserveDate"
            rules={[{ required: true, message: "يرجى اختيار تاريخ الحجز" }]}
          >
            <DatePicker size="large" className="w-full" />
          </Form.Item>

          <Form.Item
            label="اسم العميل"
            name="CustomerID"
            rules={[{ required: true, message: "يرجى اختيار العميل" }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                (option?.children).toLowerCase().includes(input.toLowerCase())
              }
              size="large"
              placeholder="اختر العميل"
            >
              {customers.map((customer: any) => (
                <Option key={customer.CustomerID} value={customer.CustomerID}>
                  {customer.CustomerName}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="الفرع"
            name="BranchID"
            rules={[{ required: true, message: "يرجى اختيار الفرع" }]}
          >
            <Select
              size="large"
              placeholder="اختر الفرع"
              showSearch
              filterOption={(input, option: any) =>
                (option?.children).toLowerCase().includes(input.toLowerCase())
              }
            >
              {branches.map((branch: any) => (
                <Option key={branch.BranchID} value={branch.BranchID}>
                  {branch.BranchName}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="الخدمة"
            name="ServiceID"
            rules={[{ required: true, message: "يرجى اختيار الخدمة" }]}
          >
            <Select
              size="large"
              placeholder="اختر الخدمة"
              showSearch
              filterOption={(input, option: any) =>
                (option?.children).toLowerCase().includes(input.toLowerCase())
              }
              onChange={(value) => {
                const selectedService = services.find(
                  (service: any) => service.ServiceID === value
                );
                console.log("selectedService", selectedService);
                setIsTrip(selectedService.IsTrip);
              }}
            >
              {services.map((service: any) => (
                <Option key={service.ServiceID} value={service.ServiceID}>
                  {service.ServiceName}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {isTrip && (
            <Form.Item
              label="خط السير"
              name="ItineraryID"
              rules={[{ required: true, message: "يرجى إدخال خط السير" }]}
            >
              <Select
                size="large"
                placeholder="اختر خط السير"
                showSearch
                filterOption={(input, option: any) =>
                  (option?.children).toLowerCase().includes(input.toLowerCase())
                }
              >
                {itineraries.map((itinerary: any) => (
                  <Option
                    key={itinerary.ItineraryID}
                    value={itinerary.ItineraryID}
                  >
                    {itinerary.CountryFromName} - {itinerary.CountryToName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            label="تاريخ الرحلة"
            name="TripDate"
            rules={[{ required: true, message: "يرجى اختيار تاريخ الرحلة" }]}
          >
            <DatePicker size="large" className="w-full" />
          </Form.Item>

          <Form.Item
            label="عدد الأفراد"
            name="PersonsCount"
            rules={[{ required: true, message: "يرجى إدخال عدد الأفراد" }]}
          >
            <Input size="large" type="number" />
          </Form.Item>

          <Form.Item
            label="القيمة الإجمالية"
            name="Total"
            rules={[{ required: true, message: "يرجى إدخال القيمة الإجمالية" }]}
          >
            <Input size="large" type="number" />
          </Form.Item>

          <Form.Item
            label="العملة"
            name="CurrId"
            rules={[{ required: true, message: "يرجى إدخال العملة" }]}
          >
            <Select
              size="large"
              placeholder="اختر العملة"
              showSearch
              filterOption={(input, option: any) =>
                (option?.children).toLowerCase().includes(input.toLowerCase())
              }
            >
              {currencies.map((curr: any) => (
                <Option key={curr.CurrID} value={curr.CurrID}>
                  {curr.CurrName}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="المدفوع"
            name="Payed"
            rules={[{ required: true, message: "يرجى إدخال المدفوع" }]}
            // initialValue={0}
            hidden
          >
            <Input size="large" type="number" />
          </Form.Item>

          <Form.Item label="ملاحظات" name="Notes" className="col-span-2">
            <TextArea size="large" rows={2} />
          </Form.Item>

          <div className="flex items-center">
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              loading={isAdding}
            >
              حفظ
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default BookAFlight;
