import { Table, Input, Space, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import type { ColumnType } from "antd/es/table";
import { useRef, useState, useMemo } from "react";
import type { InputRef } from "antd";

interface DataTableProps<T> {
  data: T[];
  columns: ColumnType<T>[];
  loading: boolean;
  allowSearch?: boolean;
}

const DataTable = <T extends Record<string, any>>({
  data,
  columns,
  loading,
  allowSearch = true,
}: DataTableProps<T>) => {
  const searchInput = useRef<InputRef>(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const handleSearch = (
    selectedKeys: string[],
    confirm: () => void,
    dataIndex: string
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void, confirm: () => void) => {
    clearFilters();
    setSearchText("");
    setSearchedColumn("");
    confirm();
  };

  const getColumnSearchProps = (dataIndex: string): ColumnType<T> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`ابحث في ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            بحث
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            إعادة تعيين
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    render: (text) =>
      searchedColumn === dataIndex ? (
        <span>
          {text
            .toString()
            .split(new RegExp(`(${searchText})`, "gi"))
            .map((part: any, index: any) =>
              part.toLowerCase() === searchText.toLowerCase() ? (
                <mark
                  key={index}
                  style={{ backgroundColor: "#ffc069", padding: 0 }}
                >
                  {part}
                </mark>
              ) : (
                part
              )
            )}
        </span>
      ) : (
        text
      ),
  });

  const enhancedColumns = useMemo(
    () =>
      columns.map((col: any) => ({
        ...col,
        sorter: (a: any, b: any) => {
          if (!a[col.dataIndex] || !b[col.dataIndex]) return 0;
          if (
            typeof a[col.dataIndex] === "number" &&
            typeof b[col.dataIndex] === "number"
          ) {
            return a[col.dataIndex] - b[col.dataIndex];
          }
          return a[col.dataIndex]
            .toString()
            .localeCompare(b[col.dataIndex].toString(), "ar");
        },
        ...(allowSearch && col.searchable
          ? getColumnSearchProps(col.dataIndex as string)
          : {}),
      })),
    [columns, searchedColumn, searchText]
  );

  return (
    <Table
      columns={enhancedColumns}
      dataSource={data}
      bordered
      scroll={{ x: 500 }}
      pagination={{
        pageSize: 5,
        defaultPageSize: 5,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50"],
      }}
      loading={loading}
    />
  );
};

export default DataTable;
