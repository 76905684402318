import React, { useState } from "react";
import { DeleteFilled } from "@ant-design/icons";
import { Button, Modal, Table, message } from "antd";
import {
  useGetBranchesQuery,
  useDeleteBranchMutation,
} from "../../../redux/Service/api";
import { ExclamationCircleFilled, EditFilled } from "@ant-design/icons";
import Heading from "../../../Components/Heading/Heading";
import ServerError from "../../../Components/utils/ServerError";
import AddBranch from "./AddBranch";
import EditBranch from "./EditBranch";
import DataTable from "../../../Components/DataTable";

interface Branch {
  BranchID: number;
  BranchName: string;
}

const { confirm } = Modal;

const Branches: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [entityForEdit, setEntityForEdit] = useState<any>(null);

  const [deleteBranch, { isLoading: delIsLoading }] = useDeleteBranchMutation();

  const {
    data: branchesData,
    isError: isBranchesError,
    isLoading: isBranchesLoading,
  } = useGetBranchesQuery(undefined);

  const branches: Branch[] = branchesData?.ResponseObject ?? [];

  const handleDeleteBranch = (id: number) => {
    confirm({
      title: "هل أنت متأكد أنك تريد حذف هذا الفرع؟",
      icon: <ExclamationCircleFilled />,
      content: "بالضغط على موافق سيتم حذف الفرع بشكل دائم.",
      async onOk() {
        try {
          await deleteBranch(id);
          message.success("تم حذف الفرع بنجاح");
        } catch (error) {
          console.error(error);
          message.error("فشل في حذف الفرع.");
        }
      },
    });
  };

  const columns = [
    {
      title: "اسم الفرع",
      dataIndex: "BranchName",
      key: "BranchName",
      searchable: true,
    },
    {
      title: "الإجراءات",
      key: "actions",
      render: (_: any, branch: Branch) => (
        <div className="flex gap-2">
          <Button
            type="text"
            icon={<EditFilled />}
            onClick={() => {
              setEntityForEdit(branch);
              setOpenEditModal(true);
            }}
          />
          <Button
            type="text"
            icon={<DeleteFilled />}
            danger
            onClick={() => handleDeleteBranch(branch.BranchID)}
          />
        </div>
      ),
    },
  ];

  return (
    <section className="p-4 section_padding mt-0 pt-0 relative">
      <Heading heading="الفروع" />

      {isBranchesError && <ServerError />}

      <div className="text-center my-3">
        <Button
          className="w-[150px]"
          type="primary"
          onClick={() => setOpenModal(true)}
        >
          إضافة فرع
        </Button>
      </div>

      <div className="mx-auto">
        <DataTable
          columns={columns}
          data={branches}
          loading={isBranchesLoading || delIsLoading}
        />
      </div>
      <AddBranch openModal={openModal} setOpenModal={setOpenModal} />
      <EditBranch
        openModal={openEditModal}
        setOpenModal={setOpenEditModal}
        entity={entityForEdit}
      />
    </section>
  );
};

export default Branches;
