import React, { useState } from "react";
import {
  DeleteFilled,
  ExclamationCircleFilled,
  EditFilled,
} from "@ant-design/icons";
import { Table, Button, Modal, Form, message } from "antd";
import {
  useGetCarsQuery,
  useGetItinerariesQuery,
  useDeleteItineraryMutation,
} from "../../../redux/Service/api";
import Heading from "../../../Components/Heading/Heading";
import Add from "./Add";
import Edit from "./Edit";
import DataTable from "../../../Components/DataTable";

interface Itinerary {
  CarName: string;
  DriverName: string;
  BranchName: string;
  CountryFromName: string;
  CountryToName: string;
  ItineraryID: number;
  StartTime: string;
  ArriveTime: string;
  SitValue: number;
}

const Itineraries: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);

  const [openEditModal, setOpenEditModal] = useState(false);
  const [entityForEdit, setEntityForEdit] = useState<any>(null);

  const { data: carsData, isLoading: isCarsLoading } =
    useGetCarsQuery(undefined);

  const {
    data: ItinerariesData,
    isLoading: isItinerariesLoading,
    isError,
  } = useGetItinerariesQuery(undefined);

  const [deleteItinerary, { isLoading: delIsLoading }] =
    useDeleteItineraryMutation();

  const itineraries: Itinerary[] = ItinerariesData?.ResponseObject ?? [];
  const cars = carsData?.ResponseObject ?? [];

  const handleDeleteItinerary = (id: number) => {
    Modal.confirm({
      title: "هل أنت متأكد أنك تريد حذف هذا الخط",
      icon: <ExclamationCircleFilled />,
      content: "بالضغط على موافق سيتم حذف الخط بشكل دائم.",
      async onOk() {
        try {
          await deleteItinerary(id);
          message.success("تم حذف الخط بنجاح");
        } catch (error) {
          console.error(error);
          message.error("فشل في حذف الخط.");
        }
      },
    });
  };
  const columns = [
    {
      title: "اسم السيارة",
      dataIndex: "CarId",
      key: "CarId",
      render: (value: any) => {
        console.log("cars", cars);
        return (
          cars.find((car: any) => car.CarID === value)?.Marka +
            " - " +
            cars.find((car: any) => car.CarID === value)?.Modal || "Unknown"
        );
      },
    },
    {
      title: "اسم السائق",
      dataIndex: "DriverName",
      key: "DriverName",
      searchable: true,
    },
    {
      title: "اسم الفرع",
      dataIndex: "BranchName",
      key: "BranchName",
      searchable: true,
    },
    {
      title: "الدولة (من)",
      dataIndex: "CountryFromName",
      key: "CountryFromName",
      searchable: true,
    },
    {
      title: "الدولة (إلى)",
      dataIndex: "CountryToName",
      key: "CountryToName",
      searchable: true,
    },
    {
      title: "وقت البداية",
      dataIndex: "StartTime",
      key: "StartTime",
      searchable: true,
    },
    {
      title: "وقت الوصول",
      dataIndex: "ArriveTime",
      key: "ArriveTime",
      searchable: true,
    },
    {
      title: "سعر الكرسي",
      dataIndex: "SitValue",
      key: "SitValue",
      searchable: true,
    },
    {
      title: "الإجراءات",
      key: "actions",
      render: (_: any, iternerary: any) => (
        <div className="flex gap-2">
          <Button
            type="text"
            icon={<EditFilled />}
            onClick={() => {
              setEntityForEdit(iternerary);
              setOpenEditModal(true);
            }}
          />
          <Button
            type="text"
            icon={<DeleteFilled />}
            danger
            onClick={() => handleDeleteItinerary(iternerary.ItineraryID)}
          />
        </div>
      ),
    },
  ];

  return (
    <section className="p-4 section_padding mt-0 pt-0 relative">
      <Heading heading="خطوط السير" />

      <div className="text-center my-3">
        <Button
          className="w-[150px]"
          type="primary"
          onClick={() => setOpenModal(true)}
        >
          إضافة خط سير
        </Button>
      </div>

      <div className="mx-auto">
        <DataTable
          columns={columns}
          data={itineraries}
          loading={isItinerariesLoading}
        />
      </div>

      <Add openModal={openModal} setOpenModal={setOpenModal} />
      <Edit
        openModal={openEditModal}
        setOpenModal={setOpenEditModal}
        entity={entityForEdit}
      />
    </section>
  );
};

export default Itineraries;
