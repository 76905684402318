import { Button, Modal, Form, Input, Select, DatePicker, message } from "antd";

import {
  useGetVendorsQuery,
  useGetCarsQuery,
  useGetSellersQuery,
  useGetCountriesQuery,
  useAddVendorRequestMutation,
} from "../../../redux/Service/api";
const AddVendorRequest = ({ openModal, setOpenModal }: any) => {
  const [form] = Form.useForm();

  const [addVendorRequest, { isLoading: isAdding }] =
    useAddVendorRequestMutation();

  const { data: vendorsData, isLoading: isVendorsLoading } =
    useGetVendorsQuery(undefined);
  const { data: sellersData, isLoading: isSellersLoading } =
    useGetSellersQuery(undefined);
  const { data: countriesData, isLoading: isCountriesLoading } =
    useGetCountriesQuery(undefined);

  const { data: carsData, isLoading: isCarsLoading } =
    useGetCarsQuery(undefined);

  const vendors = vendorsData?.ResponseObject ?? [];
  const sellers = sellersData?.ResponseObject ?? [];
  const countries = countriesData?.ResponseObject ?? [];
  const cars = carsData?.ResponseObject ?? [];

  const handleAddRequest = async () => {
    try {
      const values = await form.validateFields();
      const requestPayload = {
        RequestDate: values.RequestDate.format("YYYY-MM-DD"),
        VendorID: values.VendorID,
        CarId: values.CarId,
        SellerId: values.SellerId,
        CountryFromID: values.CountryFromID,
        CountryToID: values.CountryToID,
        Price: values.Price,
      };

      await addVendorRequest(requestPayload);
      message.success("تمت إضافة طلب المورد بنجاح");
      form.resetFields();
      setOpenModal(false);
    } catch (error) {
      console.error("خطأ أثناء إضافة طلب المورد:", error);
      message.error("فشل في إضافة طلب المورد. يرجى المحاولة مرة أخرى.");
    }
  };

  return (
    <Modal
      title="إضافة طلب المورد"
      centered
      open={openModal}
      onCancel={() => {
        setOpenModal(false);
        form.resetFields();
      }}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        name="add_vendor_request_form"
        onFinish={handleAddRequest}
      >
        <Form.Item
          label="تاريخ الطلب"
          name="RequestDate"
          rules={[{ required: true, message: "يرجى اختيار التاريخ" }]}
        >
          <DatePicker
            size="large"
            className="w-full"
            placeholder="اختر التاريخ"
          />
        </Form.Item>

        <Form.Item
          label="المورد"
          name="VendorID"
          rules={[{ required: true, message: "يرجى اختيار المورد" }]}
        >
          <Select size="large" placeholder="اختر المورد">
            {vendors?.map((vendor: any) => (
              <Select.Option key={vendor.VendorID} value={vendor.VendorID}>
                {vendor.VendorName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="السيارة"
          name="CarId"
          rules={[{ required: true, message: "يرجى اختيار السيارة" }]}
        >
          <Select size="large" placeholder="اختر السيارة">
            {cars?.map((car: any) => (
              <Select.Option key={car.CarID} value={car.CarID}>
                {car.Marka} -- {car.Modal} -- {car.CarNumber}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="مندوب تحصيل"
          name="SellerId"
          rules={[{ required: true, message: "يرجى اختيار مندوب تحصيل" }]}
        >
          <Select size="large" placeholder="اختر مندوب تحصيل">
            {sellers.map((seller: any) => (
              <Select.Option key={seller.SellerID} value={seller.SellerID}>
                {seller.SellerName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="الدولة (من)"
          name="CountryFromID"
          rules={[{ required: true, message: "يرجى اختيار الدولة (من)" }]}
        >
          <Select size="large" placeholder="اختر الدولة (من)">
            {countries?.map((country: any) => (
              <Select.Option key={country.CountryID} value={country.CountryID}>
                {country.CountryName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="الدولة (إلى)"
          name="CountryToID"
          rules={[{ required: true, message: "يرجى اختيار الدولة (إلى)" }]}
        >
          <Select size="large" placeholder="اختر الدولة (إلى)">
            {countries?.map((country: any) => (
              <Select.Option key={country.CountryID} value={country.CountryID}>
                {country.CountryName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="السعر"
          name="Price"
          rules={[{ required: true, message: "يرجى إدخال السعر" }]}
        >
          <Input size="large" type="number" placeholder="أدخل السعر" />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={isAdding}
            className="w-full"
          >
            إضافة
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddVendorRequest;
