import React from "react";

const AboutUs: React.FC = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-100 to-white flex items-center justify-center py-16 px-4 mt-5">
      <div className="max-w-6xl bg-white shadow-lg rounded-lg overflow-hidden">
        {/* Header Section */}
        <div className="bg-gradient-to-r from-[#15186c] to-[#3a3e98] p-8 text-center">
          <h1 className="text-5xl font-bold text-white">عن شركة زهران</h1>
          <p className="mt-4 text-xl text-gray-200 max-w-2xl mx-auto">
            نرحب بك في زهران، الشركة الرائدة في تقديم الخدمات المتنوعة. نقدم لك
            أفضل حلول السفر والراحة مع مجموعة من الخدمات التي تشمل حجز السيارات
            وتذاكر الرحلات والمزيد.
          </p>
        </div>

        {/* Main Content */}
        <div className="p-8 grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <h2 className="text-3xl font-semibold text-gray-800 mb-4">
              خدماتنا
            </h2>
            <ul className="space-y-4 text-gray-600">
              <li className="flex items-start">
                <div className="w-3 h-3 mt-1 mr-3 bg-[#0E104F] rounded-full"></div>
                <span>حجز السيارات الخاصة والرحلات المتنوعة</span>
              </li>
              <li className="flex items-start">
                <div className="w-3 h-3 mt-1 mr-3 bg-[#0E104F] rounded-full"></div>
                <span>تقديم خدمات السفر المختلفة بأعلى جودة</span>
              </li>
              <li className="flex items-start">
                <div className="w-3 h-3 mt-1 mr-3 bg-[#0E104F] rounded-full"></div>
                <span>حجوزات للرحلات الداخلية والدولية</span>
              </li>
              <li className="flex items-start">
                <div className="w-3 h-3 mt-1 mr-3 bg-[#0E104F] rounded-full"></div>
                <span>تجربة مريحة وآمنة لعملائنا</span>
              </li>
            </ul>
          </div>

          <div>
            <h2 className="text-3xl font-semibold text-gray-800 mb-4">
              مهمتنا
            </h2>
            <p className="text-gray-600 mb-4">
              مهمتنا هي جعل تجربة السفر أكثر سلاسة وراحة. نحن نقدم خدماتنا عبر
              الإنترنت ليتمكن عملاؤنا من حجز السيارات وتذاكر الرحلات في أي وقت
              ومن أي مكان.
            </p>
            <p className="text-gray-600">
              نعمل بجد لتقديم أعلى معايير الخدمة وضمان رضا عملائنا الكامل في كل
              خطوة من الرحلة.
            </p>
          </div>
        </div>

        {/* Footer Section */}
        <div className="bg-gray-50 p-8 text-center">
          <h2 className="text-3xl font-semibold text-gray-800">
            لماذا تختارنا؟
          </h2>
          <p className="mt-4 text-lg text-gray-600 max-w-2xl mx-auto">
            اختر زهران لأننا نقدم لك تجربة حجز مريحة وسهلة مع خيارات متعددة
            لتلبية احتياجاتك. سواء كنت بحاجة لحجز سيارة أو تذكرة سفر، نحن هنا
            لضمان راحة بالك.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
