import { Button, Form, Input, Modal, message } from "antd";
import { useEditCountryMutation } from "../../../redux/Service/api";
import { useEffect } from "react";

const EditCity = ({
  openModal,
  setOpenModal,
  entity,
}: {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  entity: any;
}) => {
  const [form] = Form.useForm();
  const [editCountry, { isLoading }] = useEditCountryMutation();

  const handleEditCountry = async (values: { CountryName: string }) => {
    try {
      const payload = { ...values, CountryId: entity.CountryID };
      await editCountry(payload).unwrap();
      message.success("تم تعديل البلد بنجاح");
      form.resetFields();
      setOpenModal(false);
    } catch (error) {
      console.error("خطأ أثناء تعديل البلد:", error);
      message.error("فشل في تعديل البلد. يرجى المحاولة مرة أخرى.");
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      CountryName: entity?.CountryName || "",
    });
  }, [entity]);

  const handleCancel = () => {
    form.resetFields();
    setOpenModal(false);
  };

  return (
    <Modal
      title="تعديل البلد"
      centered
      open={openModal}
      onCancel={handleCancel}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleEditCountry}
        autoComplete="off"
      >
        {/* Country Name Input */}
        <Form.Item
          label="اسم البلد"
          name="CountryName"
          rules={[{ required: true, message: "يرجى إدخال اسم البلد" }]}
        >
          <Input size="large" placeholder="أدخل اسم البلد" />
        </Form.Item>

        {/* Submit Button */}
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading} block>
            تعديل
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditCity;
