import React, { useState } from "react";
import { DeleteFilled, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  Modal,
  Table,
  Upload,
  message,
  Row,
  Col,
  Spin,
} from "antd";
import {
  useGetBannersQuery,
  useAddBannerMutation,
  useDeleteBannerMutation,
} from "../../../redux/Service/api";
import { ExclamationCircleFilled } from "@ant-design/icons";
import Heading from "../../../Components/Heading/Heading";
import ServerError from "../../../Components/utils/ServerError";

interface Slide {
  id: number;
  photo: string;
  title: string;
}

const { confirm } = Modal;

const SliderSettings: React.FC = () => {
  const [openSliderModal, setOpenSliderModal] = useState(false);
  const [modalKey, setModalKey] = useState(0);
  const [file, setFile] = useState<File | null>(null);
  const [title, setTitle] = useState("");

  const [addBanner, { isLoading }] = useAddBannerMutation();
  const [deleteBanner, { isLoading: delIsLoading }] = useDeleteBannerMutation();

  const {
    data: allSlidesData,
    isError: isSlidesError,
    isLoading: isSlidesLoading,
  } = useGetBannersQuery(undefined);

  const allSlides: Slide[] = allSlidesData?.data ?? [];

  const handleFileChange = (fileList: any) => {
    if (fileList.length > 0) {
      setFile(fileList[0].originFileObj);
    } else {
      setFile(null);
    }
  };

  const handleAddSlider = async () => {
    if (!file) {
      message.error("يرجى رفع ملف");
      return;
    }
    if (!title) {
      message.error("يرجى إدخال عنوان");
      return;
    }
    const formData = new FormData();
    formData.append("photo", file);
    formData.append("title", title);
    formData.append("status", "active ");

    try {
      await addBanner(formData);
      message.success("تمت إضافة السلايدر بنجاح");
      setFile(null);
      setTitle("");
      setModalKey((prev) => prev + 1);
      setOpenSliderModal(false);
    } catch (error) {
      console.error("خطأ أثناء إضافة السلايدر:", error);
      message.error("فشل في إضافة السلايدر. يرجى المحاولة مرة أخرى.");
    }
  };

  const handleDeleteSlider = (id: number) => {
    confirm({
      title: "هل أنت متأكد أنك تريد حذف هذا السلايدر؟",
      icon: <ExclamationCircleFilled />,
      content: "بالضغط على موافق سيتم حذف السلايدر بشكل دائم.",
      async onOk() {
        try {
          await deleteBanner(id);
          message.success("تم حذف السلايدر بنجاح");
        } catch (error) {
          console.error(error);
          message.error("فشل في حذف السلايدر.");
        }
      },
    });
  };

  const columns = [
    {
      title: "الصورة",
      dataIndex: "photo",
      key: "photo",
      render: (photo: string) => (
        <img src={photo} alt="Slider" className="w-20 h-20 object-cover" />
      ),
    },
    {
      title: "العنوان",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "الإجراءات",
      key: "actions",
      render: (_: any, slide: Slide) => (
        <div className="flex gap-2">
          <Button
            type="text"
            icon={<DeleteFilled />}
            danger
            onClick={() => handleDeleteSlider(slide.id)}
          />
        </div>
      ),
    },
  ];

  return (
    <section className="p-4 section_padding mt-0 pt-0 relative">
      <Heading heading="إعدادات السلايدر" />

      {isSlidesError && <ServerError />}

      <div className="text-center my-3">
        <Button
          className="w-[150px]"
          type="primary"
          onClick={() => setOpenSliderModal(true)}
        >
          إضافة سلايدر
        </Button>
      </div>

      <div className=" mx-auto">
        <Table
          columns={columns}
          dataSource={allSlides}
          rowKey="id"
          loading={isSlidesLoading || delIsLoading}
          pagination={{ pageSize: 5 }}
        />
      </div>

      <Modal
        title="إضافة سلايدر"
        centered
        open={openSliderModal}
        onCancel={() => setOpenSliderModal(false)}
        footer={null}
        key={modalKey}
      >
        <div className="flex flex-col gap-4">
          <Row gutter={16}>
            <Col span={24}>
              <Input
                size="large"
                placeholder="أدخل العنوان"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Upload
                customRequest={({ file, onSuccess }) => {
                  onSuccess?.("ok");
                }}
                onChange={({ fileList }) => handleFileChange(fileList)}
                listType="picture"
                maxCount={1}
              >
                <Button icon={<UploadOutlined />}>
                  رفع الملف (بحد أقصى: 1)
                </Button>
              </Upload>
            </Col>
          </Row>
          <Button type="primary" onClick={handleAddSlider} loading={isLoading}>
            إضافة
          </Button>
        </div>
      </Modal>
    </section>
  );
};

export default SliderSettings;
