import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBus,
  faCircleLeft,
  faLocationDot,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  FormProps,
  Input,
  Select,
  Steps,
  TimePicker,
} from "antd";
import TextArea from "antd/es/input/TextArea";
type FieldType = {
  username?: string;
  password?: string;
  remember?: string;
};

const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
  console.log("Success:", values);
};

const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (errorInfo) => {
  console.log("Failed:", errorInfo);
};

// start select
const handleChange = (value: string) => {
  console.log(`selected ${value}`);
};
// end select

function Trip() {
  return (
    <div className="container grid grid-cols-1 lg:grid-cols-2 gap-10 my-10">
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="grid gap-2 md:gap-0 grid-cols-2 bg-PumpkinOrange py-5 px-5 rounded-xl CarBooking"
      >
        <Form.Item<FieldType>
          name="remember"
          wrapperCol={{ offset: 4, span: 16 }}
          className="text-center"
        >
          <Checkbox>ذهاب فقط</Checkbox>
        </Form.Item>
        <Form.Item<FieldType>
          name="remember"
          wrapperCol={{ offset: 4, span: 16 }}
          className="text-center"
        >
          <Checkbox>ذهاب وعوده</Checkbox>
        </Form.Item>

        <Form.Item<FieldType>
          label="سفر من"
          rules={[{ message: "Please input your username!" }]}
          wrapperCol={{ offset: 0, span: 20 }}
          labelCol={{ span: 4 }}
          className="col-span-2"
        >
          <Select
            defaultValue="lucy"
            style={{ width: "100%" }}
            onChange={handleChange}
            options={[
              { value: "jack", label: "Jack" },
              { value: "lucy", label: "Lucy" },
              { value: "Yiminghe", label: "yiminghe" },
              { value: "disabled", label: "Disabled", disabled: true },
            ]}
          />
        </Form.Item>
        <Form.Item<FieldType>
          label="وصولا الى"
          rules={[{ message: "Please input your username!" }]}
          wrapperCol={{ offset: 0, span: 20 }}
          labelCol={{ span: 4 }}
          className="col-span-2"
        >
          <Select
            defaultValue="lucy"
            style={{ width: "100%" }}
            onChange={handleChange}
            options={[
              { value: "jack", label: "Jack" },
              { value: "lucy", label: "Lucy" },
              { value: "Yiminghe", label: "yiminghe" },
              { value: "disabled", label: "Disabled", disabled: true },
            ]}
          />
        </Form.Item>
        <Form.Item<FieldType>
          label="تاريخ السفر"
          name="username"
          rules={[{ message: "Please input your username!" }]}
          wrapperCol={{ offset: 0, span: 20 }}
          labelCol={{ span: 4 }}
          className="col-span-2"
        >
          <DatePicker className="w-full" />
        </Form.Item>
        <Form.Item<FieldType>
          label="عدد المسافرين  "
          name="username"
          rules={[{ message: "Please input your username!" }]}
          wrapperCol={{ offset: 0, span: 20 }}
          labelCol={{ span: 4 }}
          className="col-span-2"
        >
          <Input />
        </Form.Item>

        <Form.Item className="col-span-2 flex justify-center items-center">
          <Button type="primary" className="font-semibold px-10">
            بحث
          </Button>
        </Form.Item>
      </Form>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 h-fit items-center">
        <div className="lg:col-span-2">
          <Steps
            // className="!flex-col sm:!flex-row text-sm"
            size="small"
            items={[
              {
                title: "رحله من دمياط",
                status: "finish",
                icon: <FontAwesomeIcon icon={faCircleLeft} />,
              },
              {
                title: "وصولا الى رمسيس",
                status: "finish",
                icon: <FontAwesomeIcon icon={faLocationDot} />,
              },
            ]}
          />
          <div className="grid grid-cols-3 gap-2 text-center items-center">
            <h4 className="text-start">السبت 4:30 مساءاً</h4>
            <div className="">
              <FontAwesomeIcon
                className="text-6xl text-[#262895]"
                icon={faBus}
              />
            </div>
            <h4 className="text-end">السبت 7:30 مساءاً</h4>
          </div>
        </div>
        <div className="grid grid-cols-2 items-center gap-5">
          <button
            type="button"
            className="px-5 h-fit py-2.5 rounded-sm text-white text-sm tracking-wider font-medium border border-current outline-none bg-green-700 hover:bg-green-800 active:bg-green-700"
          >
            حجز
          </button>
          <div className="text-red-600 text-center">
            <h2 className="text-2xl font-bold">330.00</h2>
            <span className="text-sm">جنيه للكرسي</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Trip;
