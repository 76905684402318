import { useNavigate } from "react-router-dom";
// import Bookings1 from "../../assets/Bookings1.png";
import Slider from "../../Sections/Slider";
import OurFeatures from "../../Sections/OurFeatures";
import OurOffers from "../../Sections/OurOffers";
import ServicesSection from "./ServicesSection";
import VideoSection from "./VideoSection";
// import AllServices from "./AllServices";

function Home() {
  const Navigate = useNavigate();

  const handleclick = () => {
    Navigate("/Trip");
  };

  return (
    <>
      <Slider />
      {/*  */}
      <div className="mt-[70px]">
        <div className="container">
          <p className="my-16 text-center mx-auto text-PumpkinOrange leading-10	md:w-[70%] tracking-wider">
            تقدم شركة زهران الراحة والأمان لمستخدميها للإستمتاع بالرحلات وذلك عن
            طريق تقديم مجموعه من الخدمات للمسافرين وبأسعار لامثيل لها
          </p>
          <ServicesSection />

          {/* <AllServices /> */}
        </div>
      </div>
      {/*  */}
      <VideoSection />

      <OurFeatures />
      <OurOffers />
    </>
  );
}

export default Home;
