import { useNavigate } from "react-router-dom";
import { primaryBaseUrl, useGetServicesQuery } from "../../redux/Service/api";
import fallBackImage from "../../assets/Bookings1.png";

// Define Service interface based on your API response
interface Service {
  ServiceID: number;
  ServiceName: string;
  ServiceTypeId: number;
  IsPerDay: boolean;
  Price: number;
  TaxRate: number;
  IsDiffSalesPurch: boolean;
  IsTotalInvoice: boolean;
  ServiceImageName: string;
  CurrName: any;
}

const AllServices = () => {
  const navigate = useNavigate();

  // Fetch services data
  const {
    data: servicesData,
    isError: isServicesError,
    isLoading: isServicesLoading,
  } = useGetServicesQuery(undefined);

  const services: Service[] = servicesData?.ResponseObject ?? [];

  if (isServicesLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <p className="text-gray-500">Loading services...</p>
      </div>
    );
  }

  if (isServicesError) {
    return (
      <div className="flex items-center justify-center h-64">
        <p className="text-red-500">
          Failed to load services. Please try again.
        </p>
      </div>
    );
  }

  if (services.length === 0) {
    return (
      <div className="flex items-center justify-center h-64">
        <p className="text-gray-500">No services available at the moment.</p>
      </div>
    );
  }

  return (
    <div className="container mx-auto py-10">
      {/* Section Title */}
      <h2 className="text-center text-2xl md:text-3xl lg:text-4xl font-bold text-gray-800 mb-8">
        الخدمات المتاحة
      </h2>

      {/* Services Grid */}
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-5 sm:gap-7 lg:gap-8 justify-center">
        {services.map((service) => (
          <div
            key={service.ServiceID}
            className="grid gap-3 p-3 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow"
            onClick={() => navigate(`/ServiceDetails/${service.ServiceID}`)} // Navigate to details page on click
          >
            {/* Service Image */}
            <img
              src={
                service.ServiceImageName
                  ? primaryBaseUrl + service.ServiceImageName
                  : fallBackImage
              }
              className="w-full object-cover h-48 rounded-md"
              alt={service.ServiceName}
            />

            {/* Service Details */}
            <div className="px-5 py-2.5 text-center">
              <h3 className="text-blue-900 text-lg font-bold mb-2">
                {service.ServiceName}
              </h3>
              <p className="text-gray-700 text-sm">
                السعر: {service.Price.toFixed(2)} {service?.CurrName ?? "جنيه"}
              </p>
              <p className="text-gray-500 text-sm">
                الضريبة: {service.TaxRate}%
              </p>
              <p className="text-gray-500 text-sm">
                {service.IsPerDay ? "باليوم" : ""}
              </p>
            </div>

            {/* Call-to-Action */}
            <button
              className="bg-blue-600 text-white text-sm font-bold py-2 px-4 rounded mt-2 hover:bg-blue-700"
              onClick={() => navigate(`/service-details/${service.ServiceID}`)}
            >
              عرض التفاصيل
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllServices;
