import React, { useState } from "react";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { Button, Modal, Table, message } from "antd";
import {
  useGetServicesQuery,
  useDeleteServiceMutation,
  primaryBaseUrl,
  useGetCurrenciesQuery,
} from "../../../redux/Service/api";
import { ExclamationCircleFilled } from "@ant-design/icons";
import Heading from "../../../Components/Heading/Heading";
import ServerError from "../../../Components/utils/ServerError";
import AddService from "./AddService";
import EditService from "./EditService";
import DataTable from "../../../Components/DataTable";

const { confirm } = Modal;

const Services: React.FC = () => {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [entityForEdit, setEntityForEdit] = useState<any>(null);
  const [openEditModal, setOpenEditModal] = useState(false);

  const [deleteService, { isLoading: delIsLoading }] =
    useDeleteServiceMutation();

  const {
    data: servicesData,
    isError: isServicesError,
    isLoading: isServicesLoading,
  } = useGetServicesQuery(undefined);

  const services: any = servicesData?.ResponseObject ?? [];

  const {
    data: currenciesData,
    isError,
    isLoading: isCurrenciesLoading,
  } = useGetCurrenciesQuery(undefined);

  const currencies = currenciesData?.ResponseObject ?? [];

  const handleDeleteService = (id: number) => {
    confirm({
      title: "هل أنت متأكد أنك تريد حذف هذه الخدمة؟",
      icon: <ExclamationCircleFilled />,
      content: "بالضغط على موافق سيتم حذف الخدمة بشكل دائم.",
      async onOk() {
        try {
          await deleteService(id);
          message.success("تم حذف الخدمة بنجاح");
        } catch (error) {
          console.error(error);
          message.error("فشل في حذف الخدمة.");
        }
      },
    });
  };

  const columns = [
    {
      title: "رقم الخدمة",
      dataIndex: "ServiceID",
      key: "ServiceID",
      searchable: true,
    },
    {
      title: "الصورة",
      dataIndex: "ServiceImageName",
      key: "ServiceImageName",
      render: (image: string) =>
        image ? (
          <img
            src={primaryBaseUrl + image}
            alt="Service"
            className="w-20 h-20 object-cover"
          />
        ) : (
          "لا توجد صورة"
        ),
    },
    {
      title: "اسم الخدمة",
      dataIndex: "ServiceName",
      key: "ServiceName",
      searchable: true,
    },
    {
      title: "الوصف",
      dataIndex: "Description",
      key: "Description",
    },
    {
      title: "السعر",
      dataIndex: "Price",
      key: "Price",
      render: (price: number) => `${price.toFixed(2)}`,
    },
    {
      title: "العملة",
      dataIndex: "CurrName",
      key: "CurrName",
    },

    {
      title: "الضريبة",
      dataIndex: "TaxRate",
      key: "TaxRate",
      render: (taxRate: number) => `${taxRate}%`,
    },
    {
      title: "الخصم",
      dataIndex: "DiscountPercent",
      key: "DiscountPercent",
      render: (discountPercent: number) => `${discountPercent}%`,
    },
    {
      title: "نوع سعر الخدمة",
      dataIndex: "IsPerDay",
      key: "IsPerDay",
      render: (IsPerDay: number) => `${IsPerDay ? "باليوم" : "بالخدمة"}`,
    },

    {
      title: "الإجراءات",
      key: "actions",
      render: (_: any, service: any) => (
        <div className="flex gap-2">
          <Button
            type="text"
            icon={<EditFilled />}
            onClick={() => {
              setEntityForEdit(service);
              setOpenEditModal(true);
            }}
          />
          <Button
            type="text"
            icon={<DeleteFilled />}
            danger
            onClick={() => handleDeleteService(service.ServiceID)}
          />
        </div>
      ),
    },
  ];

  return (
    <section className="p-4 section_padding mt-0 pt-0 relative">
      <Heading heading="الخدمات" />

      {isServicesError && <ServerError />}

      <div className="text-center my-3">
        <Button
          className="w-[150px]"
          type="primary"
          onClick={() => setOpenAddModal(true)}
        >
          إضافة خدمة
        </Button>
      </div>

      <div className="mx-auto">
        <DataTable
          columns={columns}
          data={services}
          loading={isServicesLoading || delIsLoading}
        />
      </div>
      <AddService
        openModal={openAddModal}
        setOpenModal={setOpenAddModal}
        currencies={currencies}
      />
      <EditService
        currencies={currencies}
        openModal={openEditModal}
        setOpenModal={setOpenEditModal}
        entity={entityForEdit}
      />
    </section>
  );
};

export default Services;
