import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define two base URLs
export const primaryBaseUrl = "https://api.prosofteg.com:5001/";
const secondaryBaseUrl = "https://zahran.prosofteg.com/";

// Base query function with dynamic base URL logic
const dynamicBaseQuery = async (args, api, extraOptions) => {
  // the issue is args is a string in get requests and an object in post requests
  let isSecondaryUrl;
  if (args.url) {
    isSecondaryUrl =
      args.url.includes("api/banners") ||
      args.url.includes("api/admin/banners");
  } else if (args) {
    isSecondaryUrl = args.includes("api/banners");
  }
  const baseUrl = isSecondaryUrl ? secondaryBaseUrl : primaryBaseUrl;
  console.log("baseUrl", baseUrl);
  const userData = localStorage.getItem("zahranUserData");
  const anonymousToken = "TVRZPTpZUT09OllRPT06Y0ZKUlVWaHNRWEJwYm5NOTpNZz09";
  const basicToken = JSON.parse(userData)?.Token;
  const sectoken =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL3phaHJhbi5wcm9zb2Z0ZWcuY29tL2FwaS9sb2dpbiIsImlhdCI6MTczNTM5MDk1OSwibmJmIjoxNzM1MzkwOTU5LCJqdGkiOiI3ZWFRRGpQZVdRTjYxU3FLIiwic3ViIjoiNyIsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjcifQ.ONl11NwDwQgTqBvtnXk2q1IhPy8irefIkDKULD60ajA";
  // Call fetchBaseQuery with the correct base URL
  const rawBaseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { endpoint }) => {
      headers.set("Accept", "application/json");
      if (isSecondaryUrl) {
        headers.set("Authorization", `Bearer ${sectoken}`);
      } else {
        if (basicToken) {
          headers.set("Authorization", `Bearer ${basicToken}`);
        } else {
          headers.set("Authorization", `Bearer ${anonymousToken}`);
        }
      }
      return headers;
    },
  });

  return rawBaseQuery(args, api, extraOptions);
};

// Create the API
export const api = createApi({
  reducerPath: "api",
  baseQuery: dynamicBaseQuery, // Use the dynamic base query
  endpoints: (builder) => ({
    // ******** Auth ********* //
    register: builder.mutation({
      query: (userData) => ({
        url: "api/Identity/Users",
        method: "POST",
        body: userData,
      }),
      invalidatesTags: ["User"],
    }),
    login: builder.mutation({
      query: (loginData) => ({
        url: "api/Identity/LogIn",
        method: "POST",
        body: loginData,
      }),
      invalidatesTags: ["User"],
    }),
    logout: builder.mutation({
      query: () => ({
        url: "api/auth/logout",
        method: "POST",
      }),
      invalidatesTags: ["User"],
    }),

    getLogedUser: builder.query({
      query: () => "api/auth/show",
      providesTags: ["User"],
    }),

    // Cars
    getCars: builder.query({
      query: () => "api/Transport/Cars",
      providesTags: ["cars"],
    }),
    getAvailableCars: builder.query({
      query: () => "api/Transport/AvailableCars",
      providesTags: ["cars"],
    }),
    addCar: builder.mutation({
      query: (formData) => ({
        url: "api/Transport/Cars",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["cars"],
    }),
    editCar: builder.mutation({
      query: (formData) => ({
        url: "api/Transport/Cars",
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["cars"],
    }),
    deleteCar: builder.mutation({
      query: (carId) => ({
        url: `api/Transport/Cars?CarID=${carId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["cars"],
    }),

    // Users
    getUsers: builder.query({
      query: () => "api/Identity/Users",
      providesTags: ["users"],
    }),
    addUser: builder.mutation({
      query: (formData) => ({
        url: "api/Identity/Users",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["users"],
    }),
    deleteUser: builder.mutation({
      query: (userId) => ({
        url: `api/Identity/Users?UserID=${userId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["users"],
    }),

    // Customers
    getCustomers: builder.query({
      query: () => "api/Sales/Customers",
      providesTags: ["customers"],
    }),
    addCustomer: builder.mutation({
      query: (formData) => ({
        url: "api/Sales/Customers",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["customers"],
    }),

    editCustomer: builder.mutation({
      query: (formData) => ({
        url: "api/Sales/Customers",
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["customers"],
    }),

    deleteCustomer: builder.mutation({
      query: (customerId) => ({
        url: `api/Sales/Customers?CustomerID=${customerId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["customers"],
    }),

    // Drivers
    getDrivers: builder.query({
      query: () => "api/Transport/Drivers",
      providesTags: ["drivers"],
    }),
    addDriver: builder.mutation({
      query: (formData) => ({
        url: "api/Transport/Drivers",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["drivers"],
    }),
    editDriver: builder.mutation({
      query: (formData) => ({
        url: "api/Transport/Drivers",
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["drivers"],
    }),
    deleteDriver: builder.mutation({
      query: (driverId) => ({
        url: `api/Transport/Drivers?DriverId=${driverId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["drivers"],
    }),

    // Countries
    getCountries: builder.query({
      query: () => "api/Sys/Countries", // Adjust endpoint for countries
      providesTags: ["countries"],
    }),
    addCountry: builder.mutation({
      query: (formData) => ({
        url: "api/Sys/Countries", // Adjust endpoint for adding countries
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["countries"],
    }),
    editCountry: builder.mutation({
      query: (formData) => ({
        url: "api/Sys/Countries",
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["countries"],
    }),
    deleteCountry: builder.mutation({
      query: (countryId) => ({
        url: `api/Sys/Countries?CountryID=${countryId}`, // Adjust endpoint for deleting countries
        method: "DELETE",
      }),
      invalidatesTags: ["countries"],
    }),

    // Trip Types
    getTripTypes: builder.query({
      query: () => "api/Transport/ServiceTypes",
      providesTags: ["ServiceTypes"],
    }),
    addTripType: builder.mutation({
      query: (formData) => ({
        url: "api/Transport/ServiceTypes",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["ServiceTypes"],
    }),
    editTripType: builder.mutation({
      query: (formData) => ({
        url: "api/Transport/ServiceTypes",
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["ServiceTypes"],
    }),
    deleteTripType: builder.mutation({
      query: (serviceTypeId) => ({
        url: `api/Transport/ServiceTypes?ServiceTypeId=${serviceTypeId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ServiceTypes"],
    }),

    // Services
    getServices: builder.query({
      query: () => "api/Transport/Services",
      providesTags: ["services"],
    }),
    getServiceDetails: builder.query({
      query: (serviceID) => `api/Transport/Services?ServiceId=${serviceID}`,
      providesTags: ["services"],
    }),
    addService: builder.mutation({
      query: (formData) => ({
        url: "api/Transport/Services",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["services"],
    }),
    editService: builder.mutation({
      query: (formData) => ({
        url: "api/Transport/Services",
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["services"],
    }),
    deleteService: builder.mutation({
      query: (serviceId) => ({
        url: `api/Transport/Services?ServiceId=${serviceId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["services"],
    }),

    // Reservation
    getReservations: builder.query({
      query: () => "api/Transport/Reservation",
      providesTags: ["reservations"],
    }),
    getCustomerReservations: builder.query({
      query: (customerID) =>
        `api/Transport/ReservationCash?CsutomerId=${customerID}`,
      providesTags: ["reservations"],
    }),

    getReservationDetails: builder.query({
      query: (reservationID) =>
        `api/Transport/Reservation?ReserveId=${reservationID}`,
      providesTags: ["reservations"],
    }),

    addReservation: builder.mutation({
      query: (formData) => ({
        url: "api/Transport/Reservation",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["reservations"],
    }),
    deleteReservation: builder.mutation({
      query: (sliderId) => ({
        url: `api/Transport/Reservation?ReserveId=${sliderId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["reservations"],
    }),

    // Add Reservation Collection
    addReservationCollection: builder.mutation({
      query: (formData) => ({
        url: "api/Fin/CashIn",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["reservations"],
    }),

    // Vendors
    getVendors: builder.query({
      query: () => "api/Purch/Vendors",
      providesTags: ["vendors"],
    }),
    addVendor: builder.mutation({
      query: (formData) => ({
        url: "api/Purch/Vendors",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["vendors"],
    }),
    deleteVendor: builder.mutation({
      query: (sliderId) => ({
        url: `api/Purch/Vendors?VendorId=${sliderId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["vendors"],
    }),

    // Sellers
    getSellers: builder.query({
      query: () => "api/Sales/Sellers",
      providesTags: ["sellers"],
    }),
    addSeller: builder.mutation({
      query: (formData) => ({
        url: "api/Sales/Sellers",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["sellers"],
    }),
    deleteSeller: builder.mutation({
      query: (sliderId) => ({
        url: `api/Sales/Sellers?SellerId=${sliderId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["sellers"],
    }),

    // VendorRequests
    getVendorRequests: builder.query({
      query: () => "api/Transport/VendorRequest",
      providesTags: ["vendorRequests"],
    }),
    addVendorRequest: builder.mutation({
      query: (formData) => ({
        url: "api/Transport/VendorRequest",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["vendorRequests"],
    }),
    editVendorRequest: builder.mutation({
      query: (formData) => ({
        url: "api/Transport/VendorRequest",
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["vendorRequests"],
    }),
    deleteVendorRequest: builder.mutation({
      query: (sliderId) => ({
        url: `api/Transport/VendorRequest?RequestNo=${sliderId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["vendorRequests"],
    }),

    // Branches
    getBranches: builder.query({
      query: () => "api/Sys/Branches",
      providesTags: ["branches"],
    }),
    addBranch: builder.mutation({
      query: (formData) => ({
        url: "api/Sys/Branches",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["branches"],
    }),
    editBranch: builder.mutation({
      query: (formData) => ({
        url: "api/Sys/Branches",
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["branches"],
    }),
    deleteBranch: builder.mutation({
      query: (sliderId) => ({
        url: `api/Sys/Branches?BranchId=${sliderId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["branches"],
    }),

    // Itineraries
    getItineraries: builder.query({
      query: () => "api/Transport/Itineraries",
      providesTags: ["itineraries"],
    }),
    addItinerary: builder.mutation({
      query: (formData) => ({
        url: "api/Transport/Itineraries",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["itineraries"],
    }),
    editItinerary: builder.mutation({
      query: (formData) => ({
        url: "api/Transport/Itineraries",
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["itineraries"],
    }),
    deleteItinerary: builder.mutation({
      query: (sliderId) => ({
        url: `api/Transport/Itineraries?ItineraryId=${sliderId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["itineraries"],
    }),

    // Currencies

    getCurrencies: builder.query({
      query: () => "api/Fin/Currs",
      providesTags: ["currencies"],
    }),
    addCurrency: builder.mutation({
      query: (formData) => ({
        url: "api/Fin/Currs",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["currencies"],
    }),
    editCurrency: builder.mutation({
      query: (formData) => ({
        url: "api/Fin/Currs",
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: ["currencies"],
    }),
    deleteCurrency: builder.mutation({
      query: (currencyId) => ({
        url: `api/Fin/Currs?CurrId=${currencyId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["currencies"],
    }),

    // Banners - Sliders
    getBanners: builder.query({
      query: () => "api/banners",
      providesTags: ["banners"],
    }),
    addBanner: builder.mutation({
      query: (formData) => ({
        url: "api/admin/banners",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["banners"],
    }),
    deleteBanner: builder.mutation({
      query: (sliderId) => ({
        url: `api/admin/banners/${sliderId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["banners"],
    }),

    // About US

    getAboutUs: builder.query({
      query: () => "api/Sys/Company",
      providesTags: ["aboutUs"],
    }),

    updateAboutUs: builder.mutation({
      query: (formData) => ({
        url: "api/Sys/Company",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["aboutUs"],
    }),
  }),
});

export const {
  //
  useRegisterMutation,
  useLoginMutation,
  useGetLogedUserQuery,
  useLogoutMutation,
  // Cars
  useGetCarsQuery,
  useGetAvailableCarsQuery,
  useAddCarMutation,
  useEditCarMutation,
  useDeleteCarMutation,

  // Banners
  useGetBannersQuery,
  useAddBannerMutation,
  useDeleteBannerMutation,

  // Trip Types
  useGetTripTypesQuery,
  useAddTripTypeMutation,
  useEditTripTypeMutation,
  useDeleteTripTypeMutation,

  // Services
  useGetServicesQuery,
  useGetServiceDetailsQuery,
  useAddServiceMutation,
  useEditServiceMutation,
  useDeleteServiceMutation,

  // Drivers
  useGetDriversQuery,
  useAddDriverMutation,
  useEditDriverMutation,

  useDeleteDriverMutation,

  // Countries
  useGetCountriesQuery,
  useAddCountryMutation,
  useEditCountryMutation,
  useDeleteCountryMutation,

  // Users
  useGetUsersQuery,
  useAddUserMutation,
  useDeleteUserMutation,

  // Customers
  useGetCustomersQuery,
  useAddCustomerMutation,
  useEditCustomerMutation,
  useDeleteCustomerMutation,

  // Reservations
  useGetReservationsQuery,
  useLazyGetCustomerReservationsQuery,
  useGetReservationDetailsQuery,
  useAddReservationMutation,
  useDeleteReservationMutation,

  // Reservation Collection
  useAddReservationCollectionMutation,

  // Vendors
  useGetVendorsQuery,
  useAddVendorMutation,
  useDeleteVendorMutation,

  // Sellers
  useGetSellersQuery,
  useAddSellerMutation,
  useDeleteSellerMutation,

  // VendorRequests
  useGetVendorRequestsQuery,
  useAddVendorRequestMutation,
  useEditVendorRequestMutation,
  useDeleteVendorRequestMutation,

  // Branches
  useGetBranchesQuery,
  useAddBranchMutation,
  useEditBranchMutation,
  useDeleteBranchMutation,

  // Currencies
  useGetCurrenciesQuery,
  useAddCurrencyMutation,
  useEditCurrencyMutation,
  useDeleteCurrencyMutation,

  // Itineraries
  useGetItinerariesQuery,
  useAddItineraryMutation,
  useEditItineraryMutation,
  useDeleteItineraryMutation,

  // AboutUs
  useGetAboutUsQuery,
  useUpdateAboutUsMutation,
} = api;
