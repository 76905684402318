import { useParams } from "react-router-dom";
import {
  primaryBaseUrl,
  useGetServiceDetailsQuery,
} from "../../redux/Service/api";
import { Button, Card, Skeleton, message } from "antd";
import { useState } from "react";
import AddReservationModal from "./AddReservationModal";
import { useSelector } from "react-redux";
import fallBackImage from "../../assets/Bookings1.png";

const ServiceDetails = () => {
  const { serviceId } = useParams();
  const userData = useSelector((state: any) => state.auth.userData);
  const [openAddReservationModal, setOpenAddReservationModal] = useState(false);

  const {
    data: serviceDetailsData,
    isLoading: isServiceDetailsLoading,
    isError: isServiceDetailsError,
  } = useGetServiceDetailsQuery(serviceId);

  // make another useGetService

  const serviceDetails = serviceDetailsData?.ResponseObject?.[0] || {};

  if (isServiceDetailsLoading) {
    return (
      <div className="container flex items-center justify-start gap-4 h-[64vh]">
        <Card className="w-64">
          <Skeleton active />
        </Card>
      </div>
    );
  }

  if (isServiceDetailsError) {
    return (
      <div className="flex items-center justify-center h-64">
        <p className="text-red-500">
          Failed to load service details. Please try again.
        </p>
      </div>
    );
  }

  if (!serviceDetails) {
    return (
      <div className="flex items-center justify-center h-[64vh]">
        <p className="text-gray-500 font-bold text-4xl">
          تفاصيل الخدمة غير موجودة
        </p>
      </div>
    );
  }

  return (
    <div className="container mx-auto py-10 mt-20 px-5">
      {/* Section Title */}
      <h2 className="text-center text-3xl lg:text-4xl font-extrabold text-blue-800 mb-10">
        تفاصيل الخدمة
      </h2>

      {/* Service Details Card */}
      <div className="bg-white p-8 rounded-lg text-center lg:text-start shadow-lg flex flex-col lg:flex-row items-center lg:items-start gap-8 border-l-4 border-blue-600">
        {/* Service Info */}
        <div className="flex-1">
          <h3 className="text-2xl font-bold text-blue-700 mb-4">
            {serviceDetails.ServiceName || "اسم الخدمة غير متوفر"}
          </h3>
          <ul className="space-y-3 text-lg text-gray-700">
            <li>
              <strong className="text-gray-800">رقم الخدمة:</strong>{" "}
              {serviceDetails.ServiceID}
            </li>
            <li>
              <strong className="text-gray-800">نوع الخدمة:</strong>{" "}
              {serviceDetails.ServiceTypeName || "غير معروف"}
            </li>
            <li>
              <strong className="text-gray-800">السعر:</strong>{" "}
              {serviceDetails?.Price?.toFixed(2)}{" "}
              {serviceDetails?.CurrName ?? "جنيه"}
            </li>
            {serviceDetails?.DiscountPercent ? (
              <li>
                <strong className="text-gray-800">الخصم:</strong>{" "}
                {serviceDetails.DiscountPercent}%
              </li>
            ) : (
              <></>
            )}
            <li>
              <strong className="text-gray-800">الضريبة:</strong>{" "}
              {serviceDetails.TaxRate}%
            </li>
            <li>
              <strong className="text-gray-800">الفوترة الكلية:</strong>{" "}
              {serviceDetails.IsTotalInvoice ? "نعم" : "لا"}
            </li>
            <li>
              <strong className="text-gray-800">الحجز باليوم:</strong>{" "}
              {serviceDetails.IsPerDay ? "نعم" : "لا"}
            </li>
          </ul>
        </div>

        {serviceDetails?.Description ? (
          <div className="flex-1">
            <h2 className="text-gray-800 text-2xl font-bold mb-4">
              برنامج الرحلة
            </h2>{" "}
            <p className="text-gray-800">{serviceDetails.Description}</p>
          </div>
        ) : (
          <></>
        )}
        {/* Service Image */}
        <div className="flex-shrink-0 w-full lg:w-1/3">
          <img
            src={
              serviceDetails.ServiceImageName
                ? primaryBaseUrl + serviceDetails.ServiceImageName
                : fallBackImage
            }
            className="rounded-lg w-full object-cover h-64 lg:h-72"
            alt={serviceDetails.ServiceName || "Service"}
          />
        </div>
      </div>

      {/* Reservation Button */}
      <div className="mt-10 text-center">
        <Button
          type="primary"
          size="large"
          className="px-8 py-2 rounded-lg text-lg bg-blue-600 hover:bg-blue-500"
          onClick={() => {
            if (userData) {
              setOpenAddReservationModal(true);
            } else {
              message.error("يرجى تسجيل الدخول للحجز");
            }
          }}
        >
          احجز الآن
        </Button>
      </div>

      {/* Reservation Modal */}

      <AddReservationModal
        open={openAddReservationModal}
        setOpen={setOpenAddReservationModal}
        serviceId={serviceDetails.ServiceID}
        customerId={userData?.CustomerID}
        serviceName={serviceDetails.ServiceName}
        servicePrice={serviceDetails.Price}
        serviceTaxRate={serviceDetails.TaxRate}
        IsTrip={serviceDetails.IsTrip}
        ReserveUserID={userData?.UserID}
      />
    </div>
  );
};

export default ServiceDetails;
