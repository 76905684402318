import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLoginMutation } from "../../redux/Service/api";
import { Form, Input, Button, message } from "antd";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { reduxLogin } from "../../redux/Slices/authSlice";

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  // Destructure the mutation function and status properties
  const [loginUser, { isLoading, isError, error }] = useLoginMutation();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (values: any) => {
    try {
      const userData = await loginUser(values).unwrap();

      dispatch(reduxLogin(userData?.ResponseObject));
      navigate("/");
    } catch (err) {
      console.error("Login failed:", err);
      message.error("Login failed. Please try again.");
    }
  };

  return (
    <div className="font-[sans-serif] mt-[80px] md:my-[50px]">
      <div className="min-h-screen container flex flex-col items-center justify-center py-6 px-4">
        <div className="grid md:grid-cols-2 items-center gap-4 max-w-6xl w-full">
          <div className="border border-gray-300 rounded-lg p-6 max-w-md shadow-[0_2px_22px_-4px_rgba(93,96,127,0.2)] max-md:mx-auto">
            <Form
              form={form}
              layout="vertical"
              onFinish={handleSubmit}
              initialValues={{ Email: "", UserPassword: "" }}
              autoComplete="off"
            >
              <div className="mb-8">
                <h3 className="text-gray-800 text-3xl font-extrabold">
                  تسجيل الدخول
                </h3>
                <p className="text-gray-500 text-sm mt-4 leading-relaxed">
                  سجّل الدخول إلى حسابك واستكشف عالمًا من الفرص. رحلتك تبدأ هنا.
                </p>
              </div>

              <Form.Item
                label="البريد الإلكتروني او رقم الهاتف"
                name="Email"
                rules={[
                  { required: true, message: "يرجى إدخال البريد الإلكتروني" },
                ]}
              >
                <Input
                  className="text-sm text-gray-800 border border-gray-300 px-4 py-3 rounded-lg outline-blue-600"
                  placeholder="أدخل البريد الإلكتروني او رقم الهاتف"
                />
              </Form.Item>

              <Form.Item
                label="كلمة المرور"
                name="UserPassword"
                rules={[{ required: true, message: "يرجى إدخال كلمة المرور" }]}
              >
                <div className="relative flex items-center">
                  <Input
                    name="UserPassword"
                    type={showPassword ? "text" : "password"}
                    className="text-sm text-gray-800 border border-gray-300 px-4 py-3 rounded-lg outline-blue-600"
                    placeholder="أدخل كلمة المرور"
                  />
                  <div
                    className="absolute left-4 cursor-pointer"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                  </div>
                </div>
              </Form.Item>

              {isError && (
                <p className="text-sm text-red-600 my-2">
                  حدث خطأ أثناء تسجيل الدخول:{" "}
                </p>
              )}

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  loading={isLoading}
                  className="text-sm tracking-wide rounded-lg"
                >
                  {isLoading ? "جاري التحميل..." : "تسجيل الدخول"}
                </Button>
              </Form.Item>

              <p className="text-sm !mt-8 text-center text-gray-800">
                ليس لديك حساب؟{" "}
                <Link
                  to="/Registration"
                  className="text-blue-600 font-semibold hover:underline ml-1 whitespace-nowrap"
                >
                  سجل هنا
                </Link>
              </p>
            </Form>
          </div>
          <div className="lg:h-[400px] md:h-[300px] max-md:mt-8">
            <img
              src="https://readymadeui.com/login-image.webp"
              className="w-full h-full max-md:w-4/5 mx-auto block object-cover"
              alt="تجربة تناول الطعام"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
