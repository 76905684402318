import { Form, Input, Button, message, DatePicker } from "antd";
import { useEffect } from "react";

import Heading from "../../../Components/Heading/Heading";
import {
  useGetAboutUsQuery,
  useUpdateAboutUsMutation,
} from "../../../redux/Service/api";
import dayjs from "dayjs";

function AboutUsSettings() {
  const [form] = Form.useForm();

  const {
    data: configData,
    isLoading: isConfigLoading,
    isError: isConfigError,
  } = useGetAboutUsQuery(undefined);
  const config = configData?.ResponseObject[0] ?? {};

  const [updateConfig, { isLoading: isUpdating }] = useUpdateAboutUsMutation();

  useEffect(() => {
    if (Object.keys(config).length) {
      console.log("aa");
      console.log("config", config);

      let data = { ...config };

      if (data.FirstDate) {
        data.FirstDate = dayjs(data.FirstDate, "YYYY-MM-DD");
      }
      if (data.LastDate) {
        data.LastDate = dayjs(data.LastDate, "YYYY-MM-DD");
      }

      form.setFieldsValue(data);
    }
  }, [config, form]);

  const onFinish = async (values: any) => {
    const body = {
      ...values,
      FirstDate: values.FirstDate
        ? dayjs(values.FirstDate).format("YYYY-MM-DD")
        : null,
      LastDate: values.LastDate
        ? dayjs(values.LastDate).format("YYYY-MM-DD")
        : null,
    };
    try {
      const response = await updateConfig(body).unwrap();
      if (response?.ResponseObject === true) {
        message.success("تم تعديل بنجاح");
      } else {
        message.error("حدث خطأ أثناء العملية");
      }
    } catch (error) {
      message.error("فشل الاتصال بالخادم");
    }
  };

  return (
    <>
      <Heading heading="عن الشركة" />
      <div className="container">
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className="grid grid-cols-2 gap-2 md:grid-cols-3"
        >
          <Form.Item
            label="اسم الشركة"
            name="CompName"
            rules={[{ required: true, message: "يرجى إدخال اسم الشركة" }]}
          >
            <Input placeholder="اسم الشركة" />
          </Form.Item>

          {/* <Form.Item label="العنوان" name="Addrss">
            <Input placeholder="العنوان" />
          </Form.Item> */}

          <Form.Item label="البريد الإلكتروني" name="Email">
            <Input placeholder="البريد الإلكتروني" />
          </Form.Item>

          <Form.Item label="رقم التسجيل" name="RegNumber">
            <Input placeholder="رقم التسجيل" />
          </Form.Item>

          <Form.Item label="البطاقة الضريبية" name="TaxCard">
            <Input placeholder="البطاقة الضريبية" />
          </Form.Item>

          <Form.Item label="الرقم الضريبي" name="RegTax">
            <Input placeholder="الرقم الضريبي" />
          </Form.Item>

          <Form.Item label="الهاتف" name="Phone">
            <Input placeholder="الهاتف" />
          </Form.Item>

          <Form.Item label="الموبايل" name="Mobile">
            <Input placeholder="الموبايل" />
          </Form.Item>

          <Form.Item label="تاريخ البداية" name="FirstDate">
            <DatePicker
              placeholder="تاريخ البداية"
              className="w-full"
              format="YYYY-MM-DD"
            />
          </Form.Item>

          <Form.Item label="تاريخ النهاية" name="LastDate">
            <DatePicker
              placeholder="تاريخ النهاية"
              className="w-full"
              format="YYYY-MM-DD"
            />
          </Form.Item>
          <Form.Item
            label="رابط الفيديو (Embed)"
            name="Addrss"
            className="col-span-3"
          >
            <Input.TextArea
              placeholder="EX:https://..."
              dir="ltr"
              size="large"
              // className="!h-[7rem]"
            />
          </Form.Item>
          <div className=" mt-5 col-span-3">
            <Button
              type="primary"
              htmlType="submit"
              loading={isConfigLoading || isUpdating}
            >
              حفظ الإعدادات
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
}

export default AboutUsSettings;
