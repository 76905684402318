import { Button, Form, Input, Modal, message } from "antd";
import { useAddCurrencyMutation } from "../../../redux/Service/api";

interface AddCurrencyProps {
  openModal: boolean;
  setOpenModal: (open: boolean) => void;
}

const AddCurrency: React.FC<AddCurrencyProps> = ({
  openModal,
  setOpenModal,
}) => {
  const [form] = Form.useForm();
  const [addCurrency, { isLoading }] = useAddCurrencyMutation();

  const handleAddCurrency = async (values: {
    CurrName: string;
    CurrPart: string;
    CurrRate: number;
    IsDefualte: boolean;
  }) => {
    try {
      await addCurrency(values).unwrap();
      message.success("تمت إضافة العملة بنجاح");
      form.resetFields();
      setOpenModal(false);
    } catch (error) {
      console.error("خطأ أثناء إضافة العملة:", error);
      message.error("فشل في إضافة العملة. يرجى المحاولة مرة أخرى.");
    }
  };

  return (
    <Modal
      title="إضافة عملة"
      centered
      open={openModal}
      onCancel={() => {
        form.resetFields();
        setOpenModal(false);
      }}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleAddCurrency}
        autoComplete="off"
      >
        <Form.Item
          label="اسم العملة"
          name="CurrName"
          rules={[{ required: true, message: "يرجى إدخال اسم العملة" }]}
        >
          <Input size="large" placeholder="أدخل اسم العملة" />
        </Form.Item>
        <Form.Item
          label="جزء العملة"
          name="CurrPart"
          rules={[{ required: true, message: "يرجى إدخال جزء العملة" }]}
        >
          <Input size="large" placeholder="أدخل جزء العملة" />
        </Form.Item>
        <Form.Item
          label="سعر الصرف"
          name="CurrRate"
          rules={[{ required: true, message: "يرجى إدخال سعر الصرف" }]}
        >
          <Input size="large" type="number" placeholder="أدخل سعر الصرف" />
        </Form.Item>
        <Form.Item
          label="العملة الافتراضية"
          name="IsDefualte"
          valuePropName="checked"
        >
          <Input type="checkbox" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading} block>
            إضافة
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddCurrency;
