import { Button, Form, Input, Modal, message } from "antd";
import { useAddCountryMutation } from "../../../redux/Service/api";

const { confirm } = Modal;

const AddCity = ({ openModal, setOpenModal }: any) => {
  const [form] = Form.useForm();

  const [addCountry, { isLoading }] = useAddCountryMutation();
  const handleAddCountry = async (values: any) => {
    try {
      await addCountry(values);
      message.success("تمت إضافة البلد بنجاح");
      form.resetFields();
      setOpenModal(false);
    } catch (error) {
      console.error("خطأ أثناء إضافة البلد:", error);
      message.error("فشل في إضافة البلد. يرجى المحاولة مرة أخرى.");
    }
  };
  return (
    <Modal
      title="إضافة بلد"
      centered
      open={openModal}
      onCancel={() => {
        form.resetFields();
        setOpenModal(false);
      }}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleAddCountry}
        autoComplete="off"
      >
        <Form.Item
          label="اسم البلد"
          name="CountryName"
          rules={[{ required: true, message: "يرجى إدخال اسم البلد" }]}
        >
          <Input size="large" placeholder="أدخل اسم البلد" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading} block>
            إضافة
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddCity;
