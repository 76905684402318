import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: false,
    userData: null,
    reservationData: null,
  },
  reducers: {
    reduxLogin: (state, action) => {
      state.isAuthenticated = true;
      state.userData = action.payload;
      localStorage.setItem("zahranUserData", JSON.stringify(action.payload));
    },
    restoreAuth: (state, action) => {
      state.isAuthenticated = true;
      state.userData = action.payload;
    },
    reduxLogout: (state) => {
      state.isAuthenticated = false;
      state.userData = null;
      localStorage.removeItem("zahranUserData");
    },
    // ... other reducers
    saveReservationData: (state, action) => {
      state.reservationData = action.payload;
    },
    removeReservationData: (state) => {
      state.reservationData = null;
    },
  },
});

export const {
  reduxLogin,
  reduxLogout,
  restoreAuth,
  saveReservationData,
  removeReservationData,
} = authSlice.actions;

export default authSlice.reducer;
