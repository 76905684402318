import React, { useState } from "react";
import { DeleteFilled } from "@ant-design/icons";
import { Button, Modal, Table, message } from "antd";
import {
  useGetCountriesQuery,
  useDeleteCountryMutation,
} from "../../../redux/Service/api";
import { ExclamationCircleFilled, EditFilled } from "@ant-design/icons";
import Heading from "../../../Components/Heading/Heading";
import ServerError from "../../../Components/utils/ServerError";
import AddCity from "./AddCity";
import EditCity from "./EditCity";
import DataTable from "../../../Components/DataTable";

const { confirm } = Modal;

interface Country {
  CountryID: number;
  CountryName: string;
}

const CityCodes: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [entityForEdit, setEntityForEdit] = useState<any>(null);

  const [deleteCountry, { isLoading: delIsLoading }] =
    useDeleteCountryMutation();
  const {
    data: countriesData,
    isError,
    isLoading: isCountriesLoading,
  } = useGetCountriesQuery(undefined);

  const countries: Country[] = countriesData?.ResponseObject ?? [];

  const handleDeleteCountry = (id: number) => {
    confirm({
      title: "هل أنت متأكد أنك تريد حذف هذا البلد؟",
      icon: <ExclamationCircleFilled />,
      content: "بالضغط على موافق سيتم حذف البلد بشكل دائم.",
      async onOk() {
        try {
          await deleteCountry(id);
          message.success("تم حذف البلد بنجاح");
        } catch (error) {
          console.error(error);
          message.error("فشل في حذف البلد.");
        }
      },
    });
  };

  const columns = [
    {
      title: "اسم البلد",
      dataIndex: "CountryName",
      key: "CountryName",
      searchable: true,
    },
    {
      title: "الإجراءات",
      key: "actions",
      render: (_: any, country: Country) => (
        <div className="flex gap-2">
          <Button
            type="text"
            icon={<EditFilled />}
            onClick={() => {
              setEntityForEdit(country);
              setOpenEditModal(true);
            }}
          />
          <Button
            type="text"
            icon={<DeleteFilled />}
            danger
            onClick={() => handleDeleteCountry(country.CountryID)}
          />
        </div>
      ),
    },
  ];

  return (
    <section className="p-4 section_padding mt-0 pt-0 relative">
      <Heading heading="البلدان" />

      {isError && <ServerError />}

      <div className="text-center my-3">
        <Button
          className="w-[150px]"
          type="primary"
          onClick={() => setOpenModal(true)}
        >
          إضافة بلد
        </Button>
      </div>

      <div className="mx-auto">
        <DataTable
          columns={columns}
          data={countries}
          loading={isCountriesLoading || delIsLoading}
        />
      </div>

      <AddCity openModal={openModal} setOpenModal={setOpenModal} />
      <EditCity
        openModal={openEditModal}
        setOpenModal={setOpenEditModal}
        entity={entityForEdit}
      />
    </section>
  );
};

export default CityCodes;
